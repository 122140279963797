<div class="mt-1 mb-1 row bg-white btn-container-row">

  <div class="ms-2 col-6">
    <input #box type="search" pInputText style="width: 100%;"
           [(ngModel)]="searchInput" (ngModelChange)="onSearchChange($event)" placeholder="Search content of documents" />
  </div>
  <div class="col-1">
    <p-button label="Search" icon="pi pi-search" style="position: relative; top: 0px; line-height: 15px; margin-right: -15px;"
              styleClass="p-button-raised p-button-text me-3" (click)="contentSearch(box.value)">
      <!--<i class="pi pi-search"></i>-->
    </p-button>
  </div>
</div>

<p-toast></p-toast>
<div class="table-container">
  <div class="custom-scrollbar" #customScrollbar (scroll)="syncScroll($event, dt1)">
    <div class="scroll-content"></div>
  </div>
  <div class="p-paginator-current d-flex align-items-center mt-2 ms-5" *ngIf="filteredCustomers.length == 0" style="height:60.5px">
    {{dataLabel}}
  </div>
  <div [ngClass]="'p-paginator p-component'" *ngIf="filteredCustomers.length>0" [attr.data-pc-section]="'root'">
    <div class="p-paginator-content-start d-flex flex-row align-items-center" [attr.data-pc-section]="'start'">
      <div class="show-label p-paginator-current d-flex align-items-center">
        Showing {{ (currentPage - 1) * signOffMatrixCount + 1 }} to {{ (currentPage - 1) * signOffMatrixCount + filteredCustomers.length }} of
        {{ totalRecords !== -1 ? totalRecords : '5000+' }} document(s)
      </div>
      <button pButton type="button"
              (click)="onPreviousPage()"
              [disabled]="currentPage === 1" class="icon-button">
        <i class="pi pi-chevron-left" style="font-size: 1rem"></i>
      </button>
      <span class="p-paginator-pages me-1">
        Page {{ currentPage }}  of  {{totalPageCount}}
      </span>
      <button pButton type="button"
              (click)="onNextPage()"
              [disabled]="!hasMoreRecords" class="icon-button">
        <i class="pi pi-chevron-right" style="font-size: 1rem"></i>
      </button>
      <p-dropdown [style]="{'width':'100px'}" [options]="pageOptions" [(ngModel)]="selectedPage" optionLabel="name" optionValue="value" [filter]="true" filterBy="name"
                  (onChange)="onPageNumberSelected()">
        <ng-template pTemplate="selectedItem">
          <div *ngIf="selectedPage">
            <div>{{selectedPage}}</div>
          </div>
        </ng-template>
        <ng-template let-option pTemplate="item">
          <div>{{option.name}}</div>
        </ng-template>
      </p-dropdown>
      <p-dropdown [options]="rowCounts"
                  [(ngModel)]="signOffMatrixCount"
                  (onChange)="onRowCountChange($event.value)"
                  [style]="{'width':'90px'}"
                  styleClass="p-paginator-rpp-dropdown"
                  placeholder="Rows per page">
      </p-dropdown>
      <button pButton type="button" label="Clear All Filters"
              (click)="resetTable()"
              class="ms-1 p-button-rounded p-button-outlined">
      </button>
    </div>
  </div>
  <div class="mt-1">
    <p-table #dt1
             [value]="filteredCustomers"
             [(selection)]="selectedCustomers"
             dataKey="key"
             [paginator]="false"
             [globalFilterFields]="['doctypedescordoctype2nddesc', 'userdefineddescription', 'preparer', 'detailreviewer', 'substantivereviewer', 'requiredreviewer', 'signer' , 'officeadmin', 'projectmanager', 'taxpayer', 'engagement', 'engagementnumber', 'year', 'workflow', 'workflowyear', 'senttogpdate', 'senttosureprepdate', 'lastmodifieddate', 'document', 'taskname', 'applicabletaxyear', 'market', 'region', 'retentionlabel', 'retentionLabelAppliedOn', 'rollforward']"
             [rows]="signOffMatrixCount"
             [tableStyle]="{ 'width': '100%' }"
             (onRowSelect)="onRowSelect($event)"
             (onRowUnselect)="onRowUnselect($event)"
             (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)"
             [metaKeySelection]="false"
             [loading]="isSignOffMatrixDataLoading"
             [scrollable]="true"
             [scrollHeight]="'70vh'"
             [resizableColumns]="true"
             columnResizeMode="expand"
             (onFilter)="onFilter($event)"
             (onSort)="onSort($event)"
             sortField="doctypedescription"
             styleClass="p-datatable-sm"
             (onColResize)="onColumnResize($event)">

      <ng-template pTemplate="header">
        <tr>
          <th alignFrozen="left" pFrozenColumn [frozen]="true" style="width: 20px"></th>
          <th alignFrozen="left" pFrozenColumn [frozen]="true" style="width: 20px"><i class="pi pi-download" pTooltip="Download document"></i></th>
          <th alignFrozen="left" pFrozenColumn [frozen]="true" style="width: 20px"><i class="pi pi-sign-out" style="color:red" pTooltip="Document is checked out"></i></th>
          <th alignFrozen="left" pFrozenColumn [frozen]="true" style="width: 20px"><i class="pi pi-tags" pTooltip="Multi Tag - Blue is source document, Red is multi tagged document" style="color:#008FD2;font-size: 1rem;"></i></th>
          <th alignFrozen="left" pFrozenColumn [frozen]="true" style="width: 20px"><i class="pi pi-file" pTooltip="Document type, click to open document. If document is greyed out it is pending upload or failed to upload."></i></th>
          <th alignFrozen="left" pSortableColumn="tqrmSignOff" pFrozenColumn [frozen]="true" style="min-width: 80px">
            <div style="display: flex; flex-direction: column;">
              <span>Sign Off</span>
              <span>Complete<p-sortIcon field="tqrmSignOff"></p-sortIcon></span>
            </div>
          </th>
          <th pResizableColumn pSortableColumn="doctype" style="min-width: 200px; max-width: 200px;">Document Type<p-sortIcon field="doctype"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="doctypedescription" style="min-width: 220px; max-width: 220px;">Document Description<p-sortIcon field="doctypedescription"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="doctype2nddescription" style="min-width: 230px; max-width: 230px; ">Document Secondary Description<p-sortIcon field="doctype2nddescription"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="userdefineddescription" style="min-width: 240px; max-width: 240px; ">User Defined Description<p-sortIcon field="userdefineddescription"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="document" style="min-width: 220px; max-width: 220px;">File Name<p-sortIcon field="document"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="year" style="min-width: 130px; max-width: 130px;">Engagement<br />Tax Year<p-sortIcon field="year"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="taxpayer" style="min-width: 198px; max-width: 198px;">Taxpayer<p-sortIcon field="taxpayer"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="projectmanager" style="min-width: 130px; max-width: 130px;">Project<br />Manager<p-sortIcon field="projectmanager"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="preparer" style="min-width: 130px; max-width: 130px;">Preparer<span class="required-field">*</span><p-sortIcon field="preparer"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="detailreviewer" style="min-width: 130px; max-width: 130px;">Detail<br />Reviewer<span class="required-field">*</span><p-sortIcon field="detailreviewer"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="substantivereviewer" style="min-width: 130px; max-width: 130px;">Substantive<br />Reviewer<p-sortIcon field="substantivereviewer"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="requiredreviewer" style="min-width: 130px; max-width: 130px;">Required<br />Reviewer<p-sortIcon field="requiredreviewer"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="signer" style="min-width: 130px; max-width: 130px;">Signer<p-sortIcon field="signer"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="officeadmin" style="min-width: 130px; max-width: 130px;">Admin<p-sortIcon field="officeadmin"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="modifiedby" style="min-width: 180px;max-width:180px;">Modified By<p-sortIcon field="lastmodifiedby"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="lastmodifieddate" style="min-width: 120px;max-width:120px;">Modified Date<p-sortIcon field="lastmodifieddate"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="workflowyear" style="min-width: 100px;max-width:100px;">Workflow<br />Tax Year<p-sortIcon field="workflowyear"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="applicabletaxyear" style="min-width: 100px;max-width:100px;">Applicable<br />Tax Year<p-sortIcon field="applicabletaxyear"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="taskname" style="min-width: 198px;max-width:198px;">Task Name<p-sortIcon field="taskname"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="senttogpdate" style="min-width: 120px;max-width:120px;">Sent to<br />GP Date<p-sortIcon field="senttogpdate"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="senttosureprepdate" style="min-width: 120px;max-width:120px;">Sent to <br /> SurePrep Date<p-sortIcon field="senttosureprepdate"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="engagement" style="min-width: 198px;max-width:198px;">Engagement<p-sortIcon field="engagement"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="workflow" style="min-width: 198px;max-width:198px;">Workflow<p-sortIcon field="workflow"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="engagementStatus" style="min-width: 130px;max-width:130px;">Engagement<br />Status<p-sortIcon field="engagementStatus"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="workflowStatus" style="min-width: 130px;max-width:130px;">Workflow<br />Status<p-sortIcon field="workflowStatus"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="engagementnumber" style="min-width: 150px;max-width:150px;">Engagement<br />Number<p-sortIcon field="engagementnumber"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="market" style="min-width: 180px;max-width:180px;">Market<p-sortIcon field="market"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="region" style="min-width: 180px;max-width:180px;">Region<p-sortIcon field="region"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="retentionlabel" style="min-width: 198px;max-width:198px;">Retention Label<p-sortIcon field="retentionlabel"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="retentionLabelAppliedOn" style="min-width: 120px;max-width:120px;">Retention Label<br /> Applied On<p-sortIcon field="retentionLabelAppliedOn"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="rollforward" style="min-width: 100px;max-width:100px;">Rollforward<p-sortIcon field="rollforward"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="statuscode" style="min-width: 198px;max-width:198px;">Status<p-sortIcon field="statuscode"></p-sortIcon></th>
        </tr>
        <tr>
          <th alignFrozen="left" pFrozenColumn [frozen]="true">
            <p-tableHeaderCheckbox style="width: 20px"></p-tableHeaderCheckbox>
          </th>
          <th alignFrozen="left" pFrozenColumn [frozen]="true">
            <div style="width:20px"></div>
          </th>
          <th alignFrozen="left" pFrozenColumn [frozen]="true">
            <div style="width:20px"></div>
          </th>
          <th alignFrozen="left" pFrozenColumn [frozen]="true">
            <div style="width:20px"></div>
          </th>
          <th alignFrozen="left" pFrozenColumn [frozen]="true">
            <div style="width:20px"></div>
          </th>
          <th alignFrozen="left" pFrozenColumn [frozen]="true">
            <p-columnFilter type="custom" style="min-width: 80px" field="tqrmSignOff" display="menu" [showApplyButton]="true" [showClearButton]="true" [hideOnClear]="true" [showOperator]="false" [showAddButton]="false" [showMatchModes]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <div class="filter-container">
                  <span style="font-size:12px;font-weight:500;margin-bottom:5px;">Sign Off Requirement Met?</span>
                  <div style="margin-top:5px;">
                    <div class="checkbox-container">
                      <input type="checkbox" id="yes" [checked]="value === true" (change)="onCheckboxChange($event, filter)" />
                      <label for="yes">Yes</label>
                    </div>
                    <div class="checkbox-container">
                      <input type="checkbox" id="no" [checked]="value === false" (change)="onCheckboxChange($event, filter)" />
                      <label for="no">No</label>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 200px; max-width: 200px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="doctype"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 220px; max-width: 220px; " [matchModeOptions]="customFilterOptions" matchMode="contains" field="doctypedescription"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 230px; max-width: 230px; " [matchModeOptions]="customFilterOptions" matchMode="contains" field="doctype2nddescription"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 240px; max-width: 240px; " [matchModeOptions]="customFilterOptions" matchMode="contains" field="userdefineddescription"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter style="height:30px; min-width: 220px; max-width: 220px;" type="text" [matchModeOptions]="customFilterOptions" matchMode="contains" field="document"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 130px; max-width: 130px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="year"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 198px; max-width: 198px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="taxpayer"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 130px;max-width:130px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="projectmanagerpipe"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 130px; max-width: 130px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="preparerpipe"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 130px; max-width: 130px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="detailreviewerpipe"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 130px; max-width: 130px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="substantivereviewerpipe"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 130px; max-width: 130px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="requiredreviewerpipe"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 130px; max-width: 130px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="signerpipe"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 130px; max-width: 130px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="officeadminpipe"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 180px;max-width: 180px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="lastmodifiedby"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="date" style="min-width: 120px;max-width:120px;" class="ms-2" field="lastmodifieddate" [hideOnClear]="true" [showOperator]="false" [showAddButton]="false" [matchModeOptions]="customDateFilterOptions" display="menu"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 100px;max-width:100px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="workflowyear"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 100px;max-width:100px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="applicabletaxyear"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 198px;max-width:198px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="taskname"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="date" style="min-width: 120px;max-width:120px;" class="ms-2" field="senttogpdate" [hideOnClear]="true" [showOperator]="false" [showAddButton]="false" [matchModeOptions]="customDateFilterOptions" display="menu"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="date" style="min-width: 120px;max-width:120px;" class="ms-3" field="senttosureprepdate" [hideOnClear]="true" [showOperator]="false" [showAddButton]="false" [matchModeOptions]="customDateFilterOptions" display="menu"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 198px;max-width:198px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="engagement"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 198px;max-width:198px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="workflow"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 130px;max-width:130px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="engagementStatus"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 130px;max-width:130px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="workflowStatus"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 150px;max-width:150px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="engagementnumber"></p-columnFilter>
          </th>

          <th>
            <p-columnFilter type="text" style="min-width: 180px;max-width:180px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="market"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 180px;max-width:180px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="region"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 198px;max-width:198px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="retentionlabel"></p-columnFilter>
          </th>
          <th>
            <p-columnFilter type="date" style="min-width: 120px;max-width:120px;" class="ms-3" field="retentionLabelAppliedOn" [hideOnClear]="true" [showOperator]="false" [showAddButton]="false" [matchModeOptions]="customDateFilterOptions" display="menu"></p-columnFilter>
          </th>
          <th>
            <div style="min-width: 100px; max-width: 100px;">
              <p-columnFilter type="text" [matchModeOptions]="customFilterOptions" matchMode="contains" field="rollforward"></p-columnFilter>
            </div>
          </th>
          <th>
            <p-columnFilter type="text" style="min-width: 198px;max-width:198px;" [matchModeOptions]="customFilterOptions" matchMode="contains" field="statuscode"></p-columnFilter>
          </th>
        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-customer>
        <tr [ngClass]="{ 'bg-yellow-100': shouldHighlightRow(customer.key), '': !shouldHighlightRow(customer.key), 'opacity-50':disableRowSelection==true }">

          <td pFrozenColumn style="width: 20px">
            <p-tableCheckbox *ngIf="customer.isPrimary === true"
                             [value]="customer"
                             [disabled]="disableRowSelection">
            </p-tableCheckbox>
          </td>
          <td pFrozenColumn style="width: 20px">
            <div>
              <span *ngIf="customer.nativeAppUrl">
                <i class="pi pi-download" (click)="downloadDocument(customer)" style="cursor:pointer"></i>
              </span>
            </div>
          </td>
          <td pFrozenColumn style="width: 20px">
            <div>
              <span *ngIf="customer.doccheckoutstatus === 'Checked Out'">
                <i class="pi pi-sign-out" style="color:red"></i>
              </span>
            </div>
          </td>
          <td pFrozenColumn style="width: 20px">
            <div>
              <span *ngIf="customer.isAssociated === true && customer.isPrimary === true">
                <i class="pi pi-tags" style="color:#008FD2;font-size: 1rem;"></i>
              </span>
              <span *ngIf="customer.isPrimary !== true">
                <i class="pi pi-tags" style="color:#E81A3B;font-size: 1rem;"></i>
              </span>
            </div>
          </td>
          <!-- File type icon -->
          <td pFrozenColumn style="width: 20px">
            <div>
              <span>
                <i>
                  <a *ngIf="customer.nativeAppUrl" href="{{customer.nativeAppUrl}}" target="_blank">
                    <img [src]="getFileTypeIconClass(customer.document)" />
                  </a>
                  <img *ngIf="!customer.nativeAppUrl" [src]="getFileTypeIconClass(customer.document)" class="greyed-out" />
                </i>
              </span>
            </div>
          </td>
          <td pFrozenColumn style="width: 80px">
            <div style="padding-left:15px">
              <span *ngIf="customer.tqrmSignOff === true" title="Meets TQRM sign off to complete the Workflow.">
                <i class="fa fa-check"></i>
              </span>
              <span *ngIf="customer.tqrmSignOff !== true" title="Does not contain the required sign off(s) to complete the Workflow.">
                <i class="fa fa-exclamation-circle"></i>
              </span>
            </div>
          </td>
          <td style="min-width: 200px; max-width: 200px;">
            <div pTooltip="{{customer.doctype}}" tooltipPosition="top" class="ellipsis">
              {{ customer.doctype}}
            </div>
          </td>
          <td style="min-width: 220px; max-width: 220px; ">
            <div pTooltip="{{customer.doctypedescription}}" tooltipPosition="top" class="ellipsis">
              {{ customer.doctypedescription}}
            </div>
          </td>
          <td style="min-width: 230px; max-width: 230px;">
            <div pTooltip="{{customer.doctype2nddescription}}" tooltipPosition="top" class="ellipsis">
              {{ customer.doctype2nddescription}}
            </div>
          </td>
          <td style="min-width: 240px; max-width: 240px;">
            <div class="ui-widget-header ui-corner-all ellipsis" pTooltip="{{ customer.userdefineddescription }}" tooltipPosition="top">
              <span *ngIf="allowEdit(customer);else static_view" class="p-input-icon-right" style="width:100%;">
                <input class="p-inputtext p-component p-element" maxlength="500" type="text" style="width:100%;" [(ngModel)]="customer.userdefineddescription" (blur)="onUddInputChange(customer)" />
              </span>
              <ng-template #static_view>
                {{ customer.userdefineddescription}}
              </ng-template>
            </div>
          </td>
          <td style="min-width: 220px; max-width: 220px;">
            <div pTooltip="{{customer.document}}" tooltipPosition="top" class="ellipsis">
              {{ customer.document}}
            </div>
          </td>
          <td style="min-width: 130px; max-width: 130px;">
            <div pTooltip="{{customer.year}}" tooltipPosition="top" class="ellipsis">
              {{ customer.year}}
            </div>
          </td>
          <td style="min-width: 198px; max-width: 198px;">
            <div pTooltip="{{customer.taxpayer}}" tooltipPosition="top" class="ellipsis">
              {{ customer.taxpayer}}
            </div>
          </td>
          <td style="min-width: 130px; max-width:130px;">
            <div *ngIf="customer.projectmanager === '' && customer.projectmanagersignoffdate === ''" class="ellipsis">
              {{ customer.projectmanager | changetoinitials:customer.projectmanagersignoffdate }}
            </div>
            <div *ngIf="customer.projectmanager != '' && customer.projectmanagersignoffdate != ''" pTooltip="Signed off by {{customer.projectmanager}} on {{customer.projectmanagersignoffdate | formatdate }}" tooltipPosition="top" class="ellipsis">
              {{ customer.projectmanager | changetoinitials:customer.projectmanagersignoffdate }}
            </div>
          </td>
          <td style="min-width: 130px; max-width: 130px;">
            <div *ngIf="customer.preparer === '' && customer.preparersignoffdate === ''" class="ellipsis">
              {{ customer.preparer | changetoinitials:customer.preparersignoffdate }}
            </div>
            <div *ngIf="customer.preparer != '' && customer.preparersignoffdate != ''" pTooltip="Signed off by {{customer.preparer}} on {{customer.preparersignoffdate | formatdate }}" tooltipPosition="top" class="ellipsis">
              {{ customer.preparer | changetoinitials:customer.preparersignoffdate }}
            </div>
          </td>
          <td style="min-width: 130px; max-width: 130px;">
            <div *ngIf="customer.detailreviewer === '' && customer.detailreviewersignoffdate === ''" class="ellipsis">
              {{ customer.detailreviewer | changetoinitials:customer.detailreviewersignoffdate }}
            </div>
            <div *ngIf="customer.detailreviewer != '' && customer.detailreviewersignoffdate != ''" pTooltip="Signed off by {{customer.detailreviewer}} on {{customer.detailreviewersignoffdate | formatdate }}" tooltipPosition="top" class="ellipsis">
              {{ customer.detailreviewer | changetoinitials:customer.detailreviewersignoffdate }}
            </div>
          </td>
          <td style="min-width: 130px; max-width: 130px;">
            <div *ngIf="customer.substantivereviewer === '' && customer.substantivereviewersignoffdate === ''" class="ellipsis">
              {{ customer.substantivereviewer | changetoinitials:customer.substantivereviewersignoffdate }}
            </div>
            <div *ngIf="customer.substantivereviewer != '' && customer.substantivereviewersignoffdate != ''" pTooltip="Signed off by {{customer.substantivereviewer}} on {{customer.substantivereviewersignoffdate | formatdate }}" tooltipPosition="top" class="ellipsis">
              {{ customer.substantivereviewer | changetoinitials:customer.substantivereviewersignoffdate }}
            </div>
          </td>
          <td style="min-width: 130px; max-width: 130px;">
            <div *ngIf="customer.requiredreviewer === '' && customer.requiredreviewersignoffdate === ''" class="ellipsis">
              {{ customer.requiredreviewer | changetoinitials:customer.requiredreviewersignoffdate }}
            </div>
            <div *ngIf="customer.requiredreviewer != '' && customer.requiredreviewersignoffdate != ''" pTooltip="Signed off by {{customer.requiredreviewer}} on {{customer.requiredreviewersignoffdate | formatdate }}" tooltipPosition="top" class="ellipsis">
              {{ customer.requiredreviewer | changetoinitials:customer.requiredreviewersignoffdate }}
            </div>
          </td>
          <td style="min-width: 130px; max-width: 130px;">
            <div *ngIf="customer.signer === '' && customer.signersignoffdate === ''" class="ellipsis">
              {{ customer.signer | changetoinitials:customer.signersignoffdate }}
            </div>
            <div *ngIf="customer.signer != '' && customer.signersignoffdate != ''" pTooltip="Signed off by {{customer.signer}} on {{customer.signersignoffdate | formatdate }}" tooltipPosition="top" class="ellipsis">
              {{ customer.signer | changetoinitials:customer.signersignoffdate }}
            </div>
          </td>
          <td style="min-width: 130px; max-width: 130px;">
            <div *ngIf="customer.officeadmin === '' && customer.officeadminsignoffdate === ''" class="ellipsis">
              {{ customer.officeadmin | changetoinitials:customer.officeadminsignoffdate }}
            </div>
            <div *ngIf="customer.officeadmin != '' && customer.officeadminsignoffdate != ''" pTooltip="Signed off by {{customer.officeadmin}} on {{customer.officeadminsignoffdate | formatdate }}" tooltipPosition="top" class="ellipsis">
              {{ customer.officeadmin | changetoinitials:customer.officeadminsignoffdate }}
            </div>
          </td>
          <td  style="min-width: 180px;max-width:180px;">
            <div  pTooltip="{{customer.lastmodifiedby}}" tooltipPosition="top" class="ellipsis">
              {{ customer.lastmodifiedby }}
            </div>
          </td>      
          <td style="min-width: 120px;max-width:120px;">
            <div pTooltip="Document last modified on {{customer.lastmodifieddate | formatdate}}" tooltipPosition="top" class="ellipsis">
              {{ customer.lastmodifieddate | formatdate }}
            </div>
          </td>
          <td style="min-width: 100px;max-width:100px;">
            <div pTooltip="{{customer.workflowyear}}" tooltipPosition="top" class="ellipsis">
              {{ customer.workflowyear}}
            </div>
          </td>
          <td style="min-width: 100px;max-width:100px;">
            <div pTooltip="Applicable tax year(s) {{customer.applicabletaxyear}}" tooltipPosition="top" class="ellipsis">
              {{ customer.applicabletaxyear }}
            </div>
          </td>
          <td style="min-width: 198px;max-width:198px;">
            <div pTooltip="{{customer.taskname}}" tooltipPosition="top" class="ellipsis">
              {{ customer.taskname}}
            </div>
          </td>
          <td style="min-width: 120px;max-width:120px;">
            <div pTooltip="Sent to Global Portal on {{customer.senttogpdate | formatdate}}" tooltipPosition="top" class="ellipsis">
              {{ customer.senttogpdate | formatdate }}
            </div>
          </td>
          <td style="min-width: 120px;max-width:120px;">
            <div pTooltip="Sent to Sure Prep on {{customer.senttosureprepdate | formatdate}}" tooltipPosition="top" class="ellipsis">
              {{ customer.senttosureprepdate | formatdate }}
            </div>
          </td>
          <td style="min-width: 198px;max-width:198px;">
            <div pTooltip="{{customer.engagement}}" tooltipPosition="top" class="ellipsis">
              {{ customer.engagement}}
            </div>
          </td>
          <td style="min-width: 198px;max-width:198px;">
            <div pTooltip="{{customer.workflow}}" tooltipPosition="top" class="ellipsis">
              {{ customer.workflow}}
            </div>
          </td>
          <td style="min-width: 130px;max-width:130px;">
            <div pTooltip="Status : {{customer.engagementStatus}}" tooltipPosition="top" class="ellipsis">
              {{ customer.engagementStatus }}
            </div>
          </td>
          <td style="min-width: 130px;max-width:130px;">
            <div pTooltip="Status : {{customer.workflowStatus}}" tooltipPosition="top" class="ellipsis">
              {{ customer.workflowStatus }}
            </div>
          </td>
          <td style="min-width: 150px;max-width:150px;">
            <div pTooltip="{{customer.engagementnumber}}" tooltipPosition="top" class="ellipsis">
              {{ customer.engagementnumber}}
            </div>
          </td>

          <td style="min-width: 180px;max-width:180px;">
            <div pTooltip="{{customer.market}}" tooltipPosition="top" class="ellipsis">
              {{ customer.market}}
            </div>
          </td>
          <td style="min-width: 180px;max-width:180px;">
            <div pTooltip="{{customer.region}}" tooltipPosition="top" class="ellipsis">
              {{ customer.region}}
            </div>
          </td>
          <td style="min-width: 198px;max-width:198px;">
            <div pTooltip="{{customer.retentionlabel}}" tooltipPosition="top" class="ellipsis">
              {{ customer.retentionlabel }}
            </div>
          </td>
          <td style="min-width: 120px;max-width:120px;">
            <div pTooltip="Rentention label applied on  : {{customer.retentionLabelAppliedOn | formatdate}}" tooltipPosition="top" class="ellipsis">
              {{customer.retentionLabelAppliedOn | formatdate}}
            </div>
          </td>
          <td style="min-width: 100px;max-width:100px;">
            <div pTooltip="{{customer.rollforward}}" tooltipPosition="top" class="ellipsis">
              {{ customer.rollforward }}
            </div>
          </td>
          <td style="min-width: 198px;max-width:198px;">
            <div pTooltip="Status : {{customer.statuscode}}" tooltipPosition="top" class="ellipsis">
              {{ customer.statuscode }}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
