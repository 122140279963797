import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ConfirmationService, MenuItem, MessageService, TreeNode } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CheckInCheckOutRequest } from '../../model/CheckInCheckOutRequest';
import { AppSettings } from '../../model/appSettings';
import { ButtonAccessRequest } from '../../model/buttonAccessRequest';
import { Buttons } from '../../model/buttons';
import { Constant } from '../../model/constant.enums';
import { Context } from '../../model/context';
import { DeleteDocumentRequest } from '../../model/deleteDocumentRequest';
import { DeleteDocuments } from '../../model/deleteDocuments';
import { DocumentTagMetadataRequest } from '../../model/documentTagMetadataRequest';
import { DocumentTagRequest } from '../../model/documentTagRequest';
import { DocumentTreeRequest } from '../../model/documentTreeRequest';
import { Documents } from '../../model/documents';
import { Dropdown } from '../../model/dropdown';
import { Entities } from '../../model/entities';
import { GetDocumentMetadata } from '../../model/getMetadata';
import { getRolesDTO } from '../../model/getRolesDTO';
import { HoldUnholdDocument } from '../../model/holdUnholdDocument';
import { HoldUnholdRequest } from '../../model/holdUnholdRequest';
import { MetadataUpdate } from '../../model/metadataUpdate';
import { SetResetRollforwardRequest } from '../../model/setResetRollforwardRequest';
import { signOffDTO } from '../../model/signOffDTO';
import { SignOffMatrix } from '../../model/signoffmatrix';
import { TreeTypes } from '../../model/treetype';
import { Year } from '../../model/year';
import { messages } from '../messages/message.constant';
import { CommonService } from '../services/common/common.service';
import { ConfigurationService } from '../services/configuration.service';
import { documentsService, yesNoResponse } from '../services/documents.service';
import { LocalService } from '../services/local.service';
import { SharePointGraphService } from '../services/sharepointgraph.service';
import { uploadService } from '../services/upload.service';
import { CustomTreeNode } from '../treeview/CustomTreeNode';
import Utilities from '../services/utilities'
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-docmanagement',
  templateUrl: './docmanagement.component.html',
  styleUrls: ['./docmanagement.component.css'],
  providers: [MessageService],
})
export class DocmanagementComponent {
  saveRequest: boolean = false;
  activeAccount!: any;
  username!: string;
  userId!: string;
  userRole!: string[];
  allowedButtonActions!: string[];
  doctypedropdowndisabled = true;
  doctypegroupdropdowndisabled = true;
  docdescriptiondropdowndisabled = true;
  //WARNING: This field shares naming convention with workflowDropdownOptionalAndReadonly but does not share any direct logic with it
  workflowdropdowndisabled = true;
  taxyeardisabled = true;
  isattaxyearlevel = false;
  isatdoctypegroupnode = false;
  isatdoctypenode = false;
  secdescriptiondropdowndisabled = true;
  isUploadDisabled = true;
  uploadSidebarDisabled = false;
  isUploading = false;
  isUpdating = false;
  files: File[] = [];
  filesUploaded = 0;
  signoffdisabled = true;
  removesignoffdisabled = true;
  signoffSidebarVisible = false;
  removeSignoffSidebarVisible = false;

  deletedisabled = true;
  downloadDisabled = true;
  recoverDisabled = false;

  editDisabled = true;
  saveDisabled = true;
  IsEditSidebarVisible = false;

  isUploadAllowed = false;
  isEditMetadataAllowed = false;
  isSignOffAllowed = false;
  isRemoveSignOffAllowed = false;
  isSentToGPAllowed = false;
  isDeleteAllowed = false;
  isRecoverAllowed = false;
  isHoldAllowed = false;
  isUnholdAllowed = false;
  isArchiveAllowed = false;
  isUnarchiveAllowed = false;
  isCheckOutAllowed = false;
  isCheckInAllowed = false;
  isRollforwardAllowed = false;
  isRemoveRollforwardAllowed = false;
  isTagDocumentAllowed = false;

  showFiller = false;
  fileUploadSidebarVisible: boolean = false;
  uploadedFiles: any[] = [];
  isSecondaryDescriptionRequired: boolean = false;
  isEditSecondaryDescriptionRequired: boolean = false;
  docTypeDD: Dropdown[] = [];
  docTypeGroupDD: Dropdown[] = [];
  docDescriptionDD: Dropdown[] = [];
  secDescriptionDD: Dropdown[] = [];

  roleDD: Dropdown[] = [];
  newRoleDD: Dropdown[] = [];
  removeRoleDD: Dropdown[] = [];

  isTagging = false;
  tagDocumentSideBarVisible: boolean = false;
  tagDocumentDD: any[] = [];
  selectedTags: any[] = [];
  associatedTags: any[] = [];
  TagLabel: string = "Associated Taxpayers";
  TagPlaceholder: string = "Select taxpayers to associate with";

  // @ts-ignore: Object is possibly 'null'.
  getMetadata: GetDocumentMetadata = new GetDocumentMetadata();

  doctypeddselectedvalue!: any;
  doctypegroupddselectedvalue!: any;
  docdescriptionddselectedvalue!: any;
  secdescriptionddselectedvalue!: any;
  rollforwardselectedvalue: Dropdown = { "name": "No", "value": "861570001" };

  roleselectedvalue!: any;
  removeRoleSelectedValue!: any;

  sendToGPDisabled = true;
  openInBrowserDisabled = true;
  moreMenuDisabled = true;
  showUserPrompt = false;
  showTaxpayerReindexPrompt = false;
  private validSignoffNode = false;
  private setValidSignoffNode(node: CustomTreeNode | null | undefined) {
    const type = node ? node.type : undefined;
    if (!type) {
      this.validSignoffNode = false;
      return;
    }

    const treeType = TreeTypes[type];
    const isWorkflowNode = TreeTypes.workflow == TreeTypes[type];
    if (isWorkflowNode) {
      this.validSignoffNode = true;
      return;
    }


    //This is derived from CreateTree in the backend)
    if (treeType == TreeTypes.doctypegroup || treeType == TreeTypes.doctype) {
      //walk up the node tree until you hit a workflow node or past where a workflow node would be
      this.setValidSignoffNode(node?.parent);
      return;
    }

    this.setValidSignoffNode(null);
  }
  client: string = "";
  clientId: string = "";
  taxpayer: string = "";
  taxpayerId: string = "";
  engagement: string = "";
  engagementId: string = "";
  workflow: string = "";
  workflowId: string = "";
  docTypeGroup: string = "";
  docTypeGroupId: string = "";
  docType: string = "";
  docTypeId: string = "";
  isWorkflowActive = false;

  //context: any[] = [];
  applicabletaxyear!: any;
  userdefineddescription!: any;
  copymetadata: boolean = false;

  items: MenuItem[] = [];

  breadcumItems: MenuItem[] = [];

  parsedContext!: Context;
  //contextValues: any[] = [];

  // these below 2 fields will represent engagement and workflow tax year in UI, dynamic also.
  engagementTaxYear: string | undefined = "";
  workflowTaxYear: string | undefined = "";

  // these below 2 fields will contain original context value of engagement and workflow tax year
  engagementTaxYearDuplicate: string | undefined = "";
  workflowTaxYearDuplicate: string | undefined = "";

  //contextType: any[] = [];
  isAtTaxpayerLevel: boolean = false;

  home!: MenuItem;

  drawerValue: boolean = true;

  selectedDocuments: SignOffMatrix[] = [];

  clientValidation: boolean = false;

  openWorkflowPopup: boolean = false;
  workflowDD: Dropdown[] = [];
  workflowselectedvalue: Dropdown = { "name": "", "value": "" };
  //WARNING: Shares naming convention with workflowdropdowndisabled but does not appear to share any logic with it
  workflowDropdownOptionalAndReadonly: boolean = false;
  isApplicableTaxYearRequired: boolean | undefined = false;
  yesNoDD: Dropdown[] = [{ "name": "Yes", "value": "861570000" }, { "name": "No", "value": "861570001" }];

  allowDirectDocumentUploadToPYEngagementConfigValue = "";
  selectedEngagementAllowDocumentUploadValue: yesNoResponse = "";

  fileclicked: boolean = false;

  disableEngagement: boolean = false;
  disableWorkflow: boolean = false;
  invalidOperationReasons: string[] = ['Pending Upload', 'Pending Metadata Update', 'Archival In Progress', 'Unarchival In Progress', 'Hold In Progress', 'Unhold In Progress', 'Error In Upload'];
  invalidDeleteOperationReasons: string[] = ['Pending Upload', 'Pending Metadata Update', 'Archival In Progress', 'Unarchival In Progress', 'Hold In Progress', 'Unhold In Progress', 'Archived'];

  options: any[] = [
    { icon: 'pi pi-angle-double-left', value: false },
    { icon: 'pi pi-angle-double-right', value: true },
  ];

  @ViewChild('menu') menu: MenuItem | undefined;
  @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload | undefined;

  dom: HTMLDivElement | undefined;

  tempMetadatas: Documents[] = [];
  selectedFileName!: string;

  years: any = [];
  selectedYears!: Year[];
  selectedYearsForEdit!: Year[];
  selectedTaxYear!: Year;
  selectedTaxYearForEdit: Year | undefined;
  appsettingsConfig: AppSettings = new AppSettings();
  engagementManagementLabel = this.appsettingsConfig.engagementManagementLabel || 'Engagement Management';
  projectManagementLabel = this.appsettingsConfig.projectManagementLabel || 'Project Management';
  zipFileMaxFileCount = this.appsettingsConfig.zipFileMaxFileCount || 500;

  constructor(
    private messageService: MessageService,
    private documentsService: documentsService,
    private confirmationService: ConfirmationService,
    private authService: MsalService,
    private commonService: CommonService,
    private render: Renderer2,
    private elementRef: ElementRef,
    private uploadService: uploadService,
    private localService: LocalService,
    private router: Router,
    private sharepointgraphService: SharePointGraphService,
    private configurationService: ConfigurationService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getContext();

    this.dom = this.elementRef.nativeElement;
    this.breadcumItems = [];

    this.activeAccount = this.authService?.instance?.getActiveAccount();
    this.username = this.activeAccount?.username;

    this.getUserId(this.username);

    this.newRoleDD = [];

    this.home = { label: this.parsedContext.clientName };

    const currentYear: number = new Date().getFullYear();

    for (let year = currentYear + 5; year >= currentYear - 25; year--) {
      this.years.push({ name: year.toString(), code: year.toString() });
    }

    this.nodeClickedSubscription();
    this.selectedRowsSubscription();
    this.buttonsDisableOnDataLoading();
    this.getAppSettingsConfiguration();
  }

  getUserId(username: string) {
    this.documentsService.getUserId(username).subscribe({
      next: (response) => {
        this.userId = response;
        this.getAllowedButtonActions();
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
        console.error(error);
      }
    })
  }

  getAllowedButtonActions() {
    const request: ButtonAccessRequest = {
      userId: this.userId,
      clientId: this.parsedContext.clientId
    };

    this.documentsService.getAllowedButtonActions(request).subscribe({
      next: (response) => {
        this.allowedButtonActions = response;
        this.isUploadAllowed = this.allowedButtonActions.includes("Upload");
        this.isEditMetadataAllowed = this.allowedButtonActions.includes("Edit_Metadata");
        this.isSignOffAllowed = this.allowedButtonActions.includes("Signoff");
        this.isRemoveSignOffAllowed = this.allowedButtonActions.includes("Remove_Signoff");
        this.isSentToGPAllowed = this.allowedButtonActions.includes("Send_To_Global_Portal");
        this.isDeleteAllowed = this.allowedButtonActions.includes("Delete");
        this.isRecoverAllowed = this.allowedButtonActions.includes("Recover");
        this.isHoldAllowed = this.allowedButtonActions.includes("Hold");
        this.isUnholdAllowed = this.allowedButtonActions.includes("Unhold");
        this.isArchiveAllowed = this.allowedButtonActions.includes("Archive");
        this.isUnarchiveAllowed = this.allowedButtonActions.includes("UnArchive");
        this.isCheckOutAllowed = this.allowedButtonActions.includes("Checkout");
        this.isCheckInAllowed = this.allowedButtonActions.includes("Checkin");
        this.isRollforwardAllowed = this.allowedButtonActions.includes("Rollforward");
        this.isRemoveRollforwardAllowed = this.allowedButtonActions.includes("Remove Rollforward");
        this.isTagDocumentAllowed = this.allowedButtonActions.includes("Tag_Document");
        this.initialiseMoreMenu();
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
        console.error(error);
      }
    });
  }

  // Get Sign Off matrix count.
  private getAppSettingsConfiguration() {
    this.configurationService.getAppSettingsConfiguration().subscribe({
      next: (response) => {
        this.appsettingsConfig = response;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  initialiseMoreMenu() {
    this.items.push({
      label: Buttons.CheckIn,
      icon: 'pi pi-sign-in',
      visible: false,
      command: () => {
        this.checkInDocuments();
      }
    });

    this.items.push({
      label: Buttons.CheckOut,
      icon: 'pi pi-sign-out',
      visible: false,
      command: () => {
        this.checkOutDocuments();
      }

    });

    this.items.push({
      label: Buttons.Archive,
      icon: 'pi pi-lock',
      visible: false,
      command: () => {
        this.archiveDocuments();
      }
    });

    this.items.push({
      label: Buttons.Unarchive,
      icon: 'pi pi-lock-open',
      visible: false,
      command: () => {
        this.unarchiveDocuments();
      }
    });

    this.items.push({
      label: Buttons.Hold,
      icon: 'pi pi-minus-circle',
      visible: false,
      command: () => {
        this.holdDocuments();
      }
    });

    this.items.push({
      label: Buttons.Unhold,
      icon: 'pi pi-circle-off',
      visible: false,
      command: () => {
        this.unholdDocuments();
      }
    });

    this.items.push({
      label: Buttons.Rollforward,
      icon: 'pi pi-flag-fill',
      visible: false,
      command: () => {
        this.setResetRollforward(true);
      }
    });

    this.items.push({
      label: Buttons.RemoveRollforward,
      icon: 'pi pi-flag',
      visible: false,
      command: () => {
        this.setResetRollforward(false);
      }
    });

    this.items.push({
      label: Buttons.TagDocument,
      icon: 'pi pi-bookmark-fill',
      visible: false,
      command: () => {
        this.openTagDocumentSideBar();
      }
    });

  }

  buttonsDisableOnDataLoading() {

    this.commonService.buttonDisableForDataLoadingEvent.subscribe((val: boolean) => {
      if (val == true) {
        this.recoverDisabled = true;
        this.uploadSidebarDisabled = true;
      }
      else if (val == false) {
        this.recoverDisabled = false;
        this.uploadSidebarDisabled = false;
      }
    });
  }

  private enableSignoffButtons(enable: boolean) {
    this.signoffdisabled = this.removesignoffdisabled = !this.validSignoffNode || !enable;
  }

  async buttonsEnableOnRowSelect() {
    let isWorkflowStatusInactive = false;
    if (await this.isWorkflowStatusInactive(this.selectedDocuments)) {
      isWorkflowStatusInactive = true;
    }
    if (this.selectedDocuments.length == 0) {
      this.uploadSidebarDisabled = false;
      this.enableSignoffButtons(false);
      this.deletedisabled = true;
      this.downloadDisabled = true;
      this.editDisabled = true;
      this.sendToGPDisabled = true;
      this.openInBrowserDisabled = true;
      this.moreMenuDisabled = true;
    }
    else if (this.selectedDocuments.length > 1) {
      this.enableSignoffButtons(true);
      this.uploadSidebarDisabled = true;
      this.deletedisabled = this.isDeleteDisabled(isWorkflowStatusInactive);
      this.downloadDisabled = this.isDownloadDisabled();
      this.editDisabled = true;
      this.moreMenuDisabled = this.isInvalidOperation(this.selectedDocuments);
      this.sendToGPDisabled = this.isDocAtTaxpayerLevel(this.selectedDocuments) || this.isInvalidOperation(this.selectedDocuments);
      this.openInBrowserDisabled = true;
    }
    else {
      const isInvalidDocument = !this.selectedDocuments[0]?.url;
      this.enableSignoffButtons(!(isInvalidDocument || isWorkflowStatusInactive));
      this.editDisabled = isInvalidDocument;
      this.openInBrowserDisabled = isInvalidDocument;
      if (isWorkflowStatusInactive || this.selectedDocuments[0]?.statuscode == 'Archived' || this.isInvalidOperation(this.selectedDocuments)) {
        if (!this.isApplicableTaxYearRequired) {
          this.editDisabled = true;
        }
      }
      else if (this.selectedDocuments[0]?.statuscode == 'Unarchived' && this.isInvalidOperation(this.selectedDocuments) === false) {
        this.editDisabled = false;
      }

      this.deletedisabled = this.isDeleteDisabled(isWorkflowStatusInactive);
      this.downloadDisabled = this.isDownloadDisabled();
      this.uploadSidebarDisabled = true;
      this.sendToGPDisabled = !this.selectedDocuments[0].engagement || this.isInvalidOperation(this.selectedDocuments);
      this.moreMenuDisabled = this.isInvalidOperation(this.selectedDocuments);
    }
    this.toggleButtonsInMoreOption(isWorkflowStatusInactive);
  }

  isInvalidOperation(selectedDocs: any): boolean {
    for (let doc of selectedDocs) {
      if (doc.url === null || doc.url === '' || this.invalidOperationReasons.includes(doc.statuscode)) {
        return true;
      }
    }
    return false;
  }

  isDocAtTaxpayerLevel(selectedDocs: any): boolean {
    for (let doc of selectedDocs) {
      if (doc.taxpayer && !doc.engagement) {
        return true;
      }
    }
    return false;
  }

  isStatusArchived(selectedDocs: any): boolean {
    for (let doc of selectedDocs) {
      if (doc.statuscode === 'Archived') {
        return true;
      }
    }
    return false;
  }

  isCheckedOut(selectedDocs: any): boolean {
    for (let doc of selectedDocs) {
      if (doc.doccheckoutstatus === 'Checked Out') {
        return true;
      }
    }
    return false;
  }

  hasRetentionLabel(selectedDocs: any): boolean {
    for (let doc of selectedDocs) {
      if (doc.retentionlabel) {
        return true;
      }
    }
    return false;
  }

  isDeleteDisabled(isWorkflowStatusInactive: any): boolean {
    if (this.selectedDocuments.every(x => x.statuscode === 'Error In Upload') === true) {
      return false;
    }

    return isWorkflowStatusInactive || this.selectedDocuments.some(x => this.invalidDeleteOperationReasons.includes(x.statuscode) || x.retentionlabel || x.doccheckoutstatus === 'Checked Out');
  }

  isDownloadDisabled(): boolean {
    if (this.selectedDocuments.some(x => x.nativeAppUrl == null
      || !(x.nativeAppUrl.length > 0)
      // PBI 84984: AC states status doesn't matter. Leaving here for reference should that change in the future
      //|| x.statuscode === 'Error In Upload'
      //|| x.statuscode === 'Error In Archival'
    ) === true) {
      return true;
    }

    return !(this.selectedDocuments.length > 0);
  }

  async isWorkflowStatusInactive(selectedDocs: any): Promise<boolean> {
    if (selectedDocs.length == 0) { return false; }
    let selecteddocsworkflowstatus: string[] = [];
    let keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
    let isWorkflowStatusInactive = false;
    try {
      selecteddocsworkflowstatus = await this.documentsService.getWorkflowStatusOfDocuments(keyListSelectedDocs).toPromise();
      isWorkflowStatusInactive = selecteddocsworkflowstatus.includes('Inactive');
    }
    catch (error) {
      console.error(error);
    }
    return isWorkflowStatusInactive;
  }

  //CONFIRM
  //signoffEnableOnNodeSelect(datalength: number, workflowlength: number) {
  //  if (workflowlength != -1 && datalength >= workflowlength) {
  //    this.signoffdisabled = false;
  //  }
  //  else {
  //    this.signoffdisabled = true;workflowd
  //  }
  //}

  private setContextualDataFromNodeData(data: CustomTreeNode[]) {
    type labelValue = {
      label: string,
      recordId: string | undefined,
      type: string
    }
    type contextData = {
      client: labelValue,
      taxpayer: labelValue,
      engagementtaxyear: labelValue,
      workflowtaxyear: labelValue,
      engagement: labelValue,
      workflow: labelValue,
      doctypegroup: labelValue,
      doctype: labelValue
    }

    const context: contextData = {} as any;

    let items: MenuItem[] | { label: string; }[] = [];
    let node;
    for (let i = data.length - 1; i >= 0; i--) {
      node = data[i];

      if (node?.label) {
        items.push({ label: node?.label, id: node.key });
        if (node.type == "client" && node.label == this.parsedContext.clientName) {
          this.home = { label: this.parsedContext.clientName, id: node.key };
        }

        const type = node.type;
        if (type) {
          context[type] = <labelValue>{
            label: node?.fullLabel?.replace(' - Workflow', '').replace(' - Engagement', '') ?? "",
            recordId: node.recordId,
            type: type
          }
        }
        //if (node.workflowTaxYear !== null) {
        //  this.workflowTaxYear = node.workflowTaxYear;
        //}
      }
    }

    console.log("label" + "\t" + context);

    items.splice(0, 1);
    this.breadcumItems = items;

    //CONFIRM
    //this.signoffEnableOnNodeSelect(data.length, workflowlength);

    this.client = context.client?.label ?? "";
    this.clientId = context.client?.recordId ?? "";
    this.taxpayer = context.taxpayer?.label ?? "";
    this.taxpayerId = context.taxpayer?.recordId ?? "";
    //if (this.workflowTaxYear) {
    //  this.year = this.workflowTaxYear;
    //}
    //else {
    //  this.year = this.context[engagementtaxyearindex];
    //}
    this.engagementTaxYear = context.engagementtaxyear?.label;
    this.engagementTaxYearDuplicate = this.engagementTaxYear;
    this.workflowTaxYear = context.workflowtaxyear?.label;
    this.workflowTaxYearDuplicate = this.workflowTaxYear;
    this.engagement = context.engagement?.label ?? "";
    this.engagementId = context.engagement?.recordId ?? "";
    this.workflow = context.workflow?.label ?? "";
    this.workflowId = context.workflow?.recordId ?? "";
    this.docTypeGroup = context.doctypegroup?.label ?? "";
    this.docTypeGroupId = context.doctypegroup?.recordId ?? "";
    this.docType = context.doctype?.label ?? "";
    this.docTypeId = context.doctype?.recordId ?? "";
  }

  private nodeClickedSubscription() {
    this.commonService.nodeClickedEvent.subscribe(async (data: CustomTreeNode[]) => {

      let selectedNode = data.length > 0 ? data[0] || null : null;

      this.validateSelectedRow(selectedNode);

      this.setValidSignoffNode(selectedNode);

      let isExists = data.some(x => x.label === "Year Not Found - Engagement");

      if (isExists === true) {
        this.clientValidation = false;
      }

      this.setContextualDataFromNodeData(data);

      // this.workflowTaxYear = "";
      const request: DocumentTreeRequest = {
        clientId: this.clientId,
        taxpayerId: this.taxpayerId,
        engagementtaxyear: this.engagementTaxYear ?? "",
        workflowtaxyear: this.workflowTaxYear ?? "",
        engagementId: this.engagementId,
        workflowId: this.workflowId,
        docTypeGroupId: this.docTypeGroupId,
        docTypeId: this.docTypeId,
        nodeType: selectedNode?.type ?? ""
      };

      this.commonService.getDocumentTreeOnNodeClick(request);
      this.isWorkflowNodeActive(this.workflowId);

      if (!this.engagement && !this.workflow) {
        this.openWorkflowPopup = false;
        this.uploadValidation();
        this.workflowDD = [];
      }

      if (this.engagement && this.workflow) {
        this.openWorkflowPopup = false;
        this.uploadValidation();
      }
      //this.isApplicableTaxYearRequired = false;
      if (this.engagement && !this.workflow) {
        this.isApplicableTaxYearRequired = selectedNode?.allowDocuments;
        if (this.fileclicked) {
          this.openWorkflowPopup = true;
          await this.setWorkflowDropdownOptionalAndReadonly(this.engagementId);
          this.uploadValidation();
        }

        if (this.engagementId) {
          this.documentsService.getMetadata(this.engagementId, Entities[Entities.msdyn_project], this.clientId, this.taxpayerId).subscribe({
            next: (response) => {
              console.log(response);
              let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
              this.workflowDD = sortedresponse;
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
              console.error(error);
            }
          });
        }
      }

      if (this.selectedDocuments.length != 0) {
        let keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
        let parameters = new HttpParams();
        parameters = parameters.append('keys', keyListSelectedDocs.join(','));
        parameters = parameters.append('userid', this.userId);
        parameters = parameters.append('clientId', this.clientId);
        this.documentsService.getRoles(parameters).subscribe({
          next: (response) => {
            let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.roleDD = sortedresponse;
            this.setNewRoleDD(this.roleDD);
          },
          error: (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
            console.error(error);
          }
        });
      }
      else {
        this.enableSignoffButtons(false);
        this.getSignOffTooltip();
      }


      if (this.client && !this.taxpayer && !this.engagement && !this.workflow) {
        this.uploadValidation();
      }

    });
  }

  validateSelectedRow(selectedNode: TreeNode<any> | null) {
    if (selectedNode) {
      if (selectedNode?.type == TreeTypes[TreeTypes.client]) {
        this.clientValidation = false;
      }
      else if (selectedNode?.type == TreeTypes[TreeTypes.engagementtaxyear]) {
        this.clientValidation = false;
      }
      else if (selectedNode?.type == TreeTypes[TreeTypes.workflowtaxyear]) {
        this.clientValidation = false;
      }
      else {
        this.clientValidation = true;
      }
    }
  }

  private getContext() {
    const context = this.localService.getData(Constant[Constant.context]);
    this.parsedContext = JSON.parse(context ?? '');
  }


  breadcrumbItemClicked(event: any) {
    console.log(event.item);
    this.commonService.breadcrumbClicked(event.item.id);
  }

  signoffRows(role: string) {
    const data: signOffDTO = new signOffDTO();
    data.username = this.userId;
    data.role = this.roleselectedvalue.value ?? role;
    data.selectedDocuments = this.selectedDocuments.map(x => x.key);
    this.documentsService.signOff(data)
      .subscribe({
        next: (response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: messages.successSignOff });
          this.roleselectedvalue = "";
          this.commonService.refreshOnOperationComplete(true);
          this.signoffSidebarVisible = false;
          this.onCloseSidebar('signoff');
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedSignOff });
          console.error(error);
        }
      });
  }

  removeSignoffRows(role: string) {
    const data: signOffDTO = new signOffDTO();
    data.username = this.userId;
    data.role = this.removeRoleSelectedValue.value ?? role;
    data.selectedDocuments = this.selectedDocuments.map(x => x.key);
    this.documentsService.removeSignOff(data)
      .subscribe({
        next: (response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: messages.successRemoveSignOff });
          this.removeRoleSelectedValue = "";
          this.commonService.refreshOnOperationComplete(true);
          this.removeSignoffSidebarVisible = false;
          this.onCloseSidebar('removesignoff');
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedRemoveSignOff });
          console.error(error);
        }
      });
  }

  sendToGlobalPortal() {
    if (!this.isSentToGPAllowed || this.sendToGPDisabled) {
      return;
    }
    this.documentsService.SendToGlobalPortal(this.selectedDocuments).subscribe({
      next: (response) => {
        console.log(response);
        let message = "Document sending to Global Portal. You can navigate away from this page. The file will copy in the background and will show the Global Portal Sent Date when complete.";
        this.messageService.add({ severity: 'success', summary: 'Success', detail: message, life: 10000 });
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
        console.error(error);
      }
    })
  }

  openSignoffPanel() {
    if (!this.isSignOffAllowed || this.signoffdisabled) {
      return;
    }
    this.roleDD = [];
    this.newRoleDD = [];
    this.roleselectedvalue = "";
    this.commonService.disableRowSelectOnPanel(true);
    let keylistselecteddocs: string[] = this.selectedDocuments.map(x => x.key);

    let parameters = new HttpParams();
    let role = "";
    parameters = parameters.append('keys', keylistselecteddocs.join(','));
    parameters = parameters.append('workflowid', this.workflowId == undefined ? "" : this.workflowId);
    parameters = parameters.append('userid', this.userId);
    parameters = parameters.append('clientid', this.clientId);
    this.documentsService.getRoles(parameters).subscribe({
      next: (response) => {
        this.roleDD = response;
        this.setNewRoleDD(this.roleDD);
        if (response.length == 1) {
          this.signoffSidebarVisible = false;
          role = this.getRoleFromResponse(response[0].name);
          this.signoffRows(role);
        }
        else if (response.length == 0) {
          this.signoffSidebarVisible = false;
          this.messageService.add({ severity: 'warn', summary: messages.roleNotAssigned, detail: messages.signOffWarning });
          this.commonService.disableRowSelectOnPanel(false);
        }
        else {
          this.signoffSidebarVisible = true;
        }
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  openRemoveSignoffPanel() {
    if (!this.isRemoveSignOffAllowed || this.removesignoffdisabled) {
      return;
    }
    this.removeRoleSelectedValue = "";
    this.commonService.disableRowSelectOnPanel(true);
    let keylistselecteddocs: string[] = this.selectedDocuments.map(x => x.key);

    let parameters = new HttpParams();
    let role = "";
    parameters = parameters.append('keys', keylistselecteddocs.join(','));
    parameters = parameters.append('userid', this.userId);
    parameters = parameters.append('clientid', this.clientId);
    this.documentsService.getAssignedRoles(parameters).subscribe({
      next: (response) => {
        this.removeRoleDD = response;
        if (response.length == 1) {
          this.removeSignoffSidebarVisible = false;
          role = this.getRoleFromResponse(response[0].name);
          this.removeSignoffRows(role);
        }
        else if (response.length == 0) {
          this.removeSignoffSidebarVisible = false;
          this.messageService.add({ severity: 'warn', summary: messages.roleNotAssigned, detail: messages.removeSignOffWarning });
          this.commonService.disableRowSelectOnPanel(false);
        }
        else {
          this.removeSignoffSidebarVisible = true;
        }
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }
  getRoleFromResponse(name: string) {
    let role = "";
    switch (name) {
      case 'Detail Reviewer': { role = 'mcs_detailreviewer'; break; }
      case 'Preparer': { role = 'mcs_preparer'; break; }
      case 'Admin': { role = 'mcs_officeadmin'; break; }
      case 'Required Reviewer': { role = 'mcs_requiredreviewer'; break; }
      case 'Signer': { role = 'mcs_signer'; break; }
      case 'Substantive Reviewer': { role = 'mcs_substantivereviewer'; break; }
      default: { role = 'mcs_projectmanager'; break; }
    }
    return role;
  }
  setNewRoleDD(roleDD: Dropdown[]) {
    for (let dd of roleDD) {
      if (dd.name == 'Detail Reviewer') {
        this.newRoleDD.push({ name: 'Detail Reviewer', value: 'mcs_detailreviewer' });
      }
      else if (dd.name == 'Admin') {
        this.newRoleDD.push({ name: 'Admin', value: 'mcs_officeadmin' });
      }
      else if (dd.name == 'Preparer') {
        this.newRoleDD.push({ name: 'Preparer', value: 'mcs_preparer' });
      }
      else if (dd.name == 'Required Reviewer') {
        this.newRoleDD.push({ name: 'Required Reviewer', value: 'mcs_requiredreviewer' });
      }
      else if (dd.name == 'Signer') {
        this.newRoleDD.push({ name: 'Signer', value: 'mcs_signer' });
      }
      else if (dd.name == 'Substantive Reviewer') {
        this.newRoleDD.push({ name: 'Substantive Reviewer', value: 'mcs_substantivereviewer' });
      }
      else if (dd.name == 'Workflow Project Manager') {
        this.newRoleDD.push({ name: 'Project Manager', value: 'mcs_projectmanager' });
      }
    }
  }


  onCloseSidebar(buttonClicked: string) {
    this.commonService.disableRowSelectOnPanel(false);
    if (buttonClicked == 'upload') {
      this.openWorkflowPopup = false;
      this.fileclicked = false;
      this.doctypedropdowndisabled = true;
      this.doctypegroupdropdowndisabled = true;
      this.docdescriptiondropdowndisabled = true;
      this.secdescriptiondropdowndisabled = true;
      this.taxyeardisabled = true;
      this.isatdoctypegroupnode = false;
      this.isatdoctypenode = false;
      this.isattaxyearlevel = false;
      this.workflowselectedvalue = new Dropdown;
      this.doctypegroupddselectedvalue = "";
      this.doctypeddselectedvalue = "";
      this.docdescriptionddselectedvalue = "";
      this.secdescriptionddselectedvalue = "";
      this.userdefineddescription = "";
      this.applicabletaxyear = "";
      this.rollforwardselectedvalue = { "name": "No", "value": "861570001" };
      this.selectedYears = [];
      this.selectedYearsForEdit = [];
      // this.taxyear = "";
      this.engagementTaxYear = "";
      this.workflowTaxYear = "";
      if (this.engagementTaxYearDuplicate) {
        this.engagementTaxYear = this.engagementTaxYearDuplicate;
      }
      if (this.workflowTaxYearDuplicate) {
        this.workflowTaxYear = this.workflowTaxYearDuplicate;
      }
      this.selectedTaxYear = { name: "", code: "" };
      this.isSecondaryDescriptionRequired = false;
      this.isUploadDisabled = true;
      //this.isApplicableTaxYearRequired = false;
      this.copymetadata = false;
      this.uploadedFiles = [];
      this.tempMetadatas = [];
      this.fileUpload!.clear();
      this.docTypeGroupDD = [];
      this.docTypeDD = [];
      this.docDescriptionDD = [];
      this.secDescriptionDD = [];
    }
    else if (buttonClicked == 'edit') {
      this.getMetadata.taxpayers = [];
      this.getMetadata.taxpayerId = "";
      this.getMetadata.selectedTaxpayer = undefined;
      this.getMetadata.engagements = [];
      this.getMetadata.engagementId = "";
      this.getMetadata.selectedEngagement = undefined;
      this.getMetadata.workflows = [];
      this.getMetadata.workflowId = "";
      this.getMetadata.selectedWorkflow = undefined;
      this.getMetadata.doctypes = [];
      this.getMetadata.doctypeId = "";
      this.getMetadata.selectedDoctype = undefined;
      this.getMetadata.doctypeGroups = [];
      this.getMetadata.doctypeGroupId = "";
      this.getMetadata.selectedDoctypeGroup = undefined;
      this.getMetadata.doctypeDescs = [];
      this.getMetadata.doctypeDescId = "";
      this.getMetadata.selectedDoctypeDesc = undefined;
      this.getMetadata.doctype2ndDescs = [];
      this.getMetadata.doctype2ndDescId = "";
      this.getMetadata.selectedDoctype2ndDesc = undefined;
      this.getMetadata.userDefineDescription = "";
      this.getMetadata.applicableTaxYear = "";
      this.getMetadata.taxYear = "";
      this.selectedYearsForEdit = [];
      this.isEditSecondaryDescriptionRequired = false;
      this.disableEngagement = false;
      this.disableWorkflow = false;
      this.showUserPrompt = false;
      this.showTaxpayerReindexPrompt = false;
    }
    else if (buttonClicked == 'signoff') {
      this.newRoleDD = [];
      this.roleselectedvalue = "";
    }
    else if (buttonClicked == 'removesignoff') {
      this.removeRoleDD = [];
      this.removeRoleSelectedValue = "";
    }
    else if (buttonClicked == 'tagdocument') {
      this.tagDocumentDD = [];
      this.selectedTags = [];
      this.associatedTags = [];
      this.tagDocumentSideBarVisible = false;
      this.isTagging = false;
    }

    this.disableWorkflow = false;
    this.workflowDropdownOptionalAndReadonly = false;
  }

  openUploadSidebar() {
    if (this.uploadSidebarDisabled) {
      return;
    }
    this.fileUploadSidebarVisible = true;
    this.commonService.disableRowSelectOnPanel(true);
    this.getContext();
    if (this.client && this.taxpayer && !this.engagement && !this.workflow) {
      this.isAtTaxpayerLevel = true;
    }
    else {
      this.isAtTaxpayerLevel = false;
    }
    this.documentsService.getDocTypeGroup(this.engagementId).subscribe({
      next: (response) => {
        console.log(response);
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.docTypeGroupDD = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  drawerHandler(event: any) {
    console.log(event);
    this.commonService.drawerClicked(event.value);
    if (this.drawerValue == true) {
      this.render.addClass(this.menu, "p-menuCustom");
    }
    this.render.addClass(this.menu, "p-menu");
  }

  // This method performs all the validations on the files selected for upload.
  onSelectFile(event: any) {
    const maxfilecount = 10;
    this.uploadedFiles = [];

    // Remove extra files if file count greater than 10.
    this.removeExtraFiles(event, maxfilecount);

    // Validate file size.
    this.validateFileSize(event.files);

    // Loop through the selected files and add valid files allowed to be uploaded.
    for (let index = event.currentFiles.length - 1; index >= 0; index--) {

      if (this.isFileNameValid(event.currentFiles[index].name) === true) {
        this.uploadedFiles.push(event.currentFiles[index]);
      } else {
        event.currentFiles.splice(index, 1);
      }
    }
  }

  // Remove extra files from the view when total number of files exceeds 10.
  removeExtraFiles(event: any, maxfilecount: any) {
    if (event.currentFiles.length > maxfilecount) {
      this.messageService.add({ severity: 'warn', summary: messages.fileUpload, detail: messages.uploadfileCountExceeded });
      while (event.currentFiles.length > maxfilecount) {
        event.currentFiles.pop();
      }
    }
  }

  // Verify if file name is valid.
  isFileNameValid(fileName: any) {
    const regex = /^(?:\.lock|CON|PRN|AUX|NUL|COM[0-9]|LPT[0-9]|_vti_|desktop\.ini)$|["*:<>?\/\\|]|_vti_|^~\$/i;

    // Validation for special characters
    if (regex.test(fileName) === true) {
      this.messageService.add({ severity: 'error', summary: messages.fileUpload, detail: `${fileName}` + messages.invalidFilename });
      return false;
    }

    return true;
  }

  // Verify if file size is valid.
  validateFileSize(files: any) {
    for (let file of files) {
      if (file.size > 2.0000E+9) {
        this.messageService.add({ severity: 'error', summary: messages.fileUpload, detail: `${file.name}` + messages.uploadSizeExceeded });
      }
    }
  }

  onCancel(event: any) {
    this.uploadedFiles = [];
    this.tempMetadatas = [];
    this.fileUpload!.clear();
    this.files = [];
    this.workflowdropdowndisabled = true;
    this.workflowselectedvalue = new Dropdown();
    this.doctypeddselectedvalue = null;
    this.doctypegroupddselectedvalue = null;
    this.docdescriptionddselectedvalue = null;
    this.secdescriptionddselectedvalue = null;
    this.applicabletaxyear = null;
    this.rollforwardselectedvalue = { "name": "No", "value": "861570001" };
    this.selectedYears = [];
    // this.taxyear = "";
    this.engagementTaxYear = "";
    this.workflowTaxYear = "";
    if (this.engagementTaxYearDuplicate) {
      this.engagementTaxYear = this.engagementTaxYearDuplicate;
    }
    if (this.workflowTaxYearDuplicate) {
      this.workflowTaxYear = this.workflowTaxYearDuplicate;
    }
    this.selectedTaxYear = { name: "", code: "" };
    this.userdefineddescription = null;
    this.uploadValidation();
    this.doctypedropdowndisabled = true;
    this.doctypegroupdropdowndisabled = true;
    this.docdescriptiondropdowndisabled = true;
    this.secdescriptiondropdowndisabled = true;
    this.taxyeardisabled = true;
    this.isUploadDisabled = true;
    this.copymetadata = false;
    this.isatdoctypegroupnode = false;
    this.isatdoctypenode = false;
    this.isattaxyearlevel = false;
  }

  async getAllowDirectDocumentUploadToPYEngagementConfigValue(): Promise<void> {
    this.allowDirectDocumentUploadToPYEngagementConfigValue = await this.documentsService.getAllowDirectDocumentUploadToPYEngagementConfigValue().toPromise();
  }
  async getSelectedEngagementAllowDocumentUploadValue(engagementId: string | undefined): Promise<void> {
    this.selectedEngagementAllowDocumentUploadValue = engagementId ?
      await this.documentsService.getSelectedEngagementAllowDocumentUploadValue(engagementId).toPromise()
      : undefined;
  }

  async onfileclick(event: any) {
    //this.isApplicableTaxYearRequired = false;
    this.fileclicked = true;

    this.selectedFileName = event.textContent.trim();

    let files = this.dom?.querySelectorAll('.files');

    this.dom?.querySelectorAll('.files').forEach(
      file => {
        file.classList.remove('focus');
      }
    )

    event.classList.add('focus');
    if (this.needsCheckWorkflowDropdownOptional) {
      this.openWorkflowPopup = true;
      this.isAtTaxpayerLevel = false;
      await this.setWorkflowDropdownOptionalAndReadonly(this.engagementId);
      this.uploadValidation();
    }

    if (!this.engagement && !this.workflow) {
      this.openWorkflowPopup = false;
      this.isAtTaxpayerLevel = true;
      this.uploadValidation();
    }

    if (this.engagement && this.workflow) {
      this.openWorkflowPopup = false;
      this.isAtTaxpayerLevel = false;
      this.uploadValidation();
    }

    if (this.client && !this.taxpayer && !this.engagement && this.workflow) {
      this.uploadValidation();
      this.isAtTaxpayerLevel = false;
    }

    if (this.workflowDropdownOptionalAndReadonly == true) {
      this.workflowdropdowndisabled = true;
    }
    else {
      this.workflowdropdowndisabled = false;
    }
    this.doctypedropdowndisabled = false;
    this.doctypegroupdropdowndisabled = false;
    this.docdescriptiondropdowndisabled = false;
    this.secdescriptiondropdowndisabled = false;
    this.isUploadDisabled = false;
    this.taxyeardisabled = false;

    if (this.selectedFileName) {
      let metadata = this.tempMetadatas.find(x => x.mcsName === this.selectedFileName);
      if (metadata) {
        this.applicabletaxyear = metadata.mcsApplicableTaxYear;
        let yearList = this.applicabletaxyear?.split(',');
        this.selectedYears = this.years?.filter((year: any) => { return yearList?.includes(year.name); })
        this.engagementTaxYear = metadata.mcsEngagementTaxYear;
        this.workflowTaxYear = metadata.mcsWorkflowTaxYear;
        this.selectedTaxYear = { name: metadata.selectedTaxYear ?? "", code: metadata.selectedTaxYear ?? "" };
        this.userdefineddescription = metadata.mcsUserDefinedDescription;
        this.docTypeGroupDD = metadata.mcsDoctypeGroupDD!;
        this.docTypeDD = metadata.mcsDoctypeDD!;
        this.docDescriptionDD = metadata.mcsDoctypeDescDD!;
        this.secDescriptionDD = metadata.mcsDoctype2ndDescDD!;
        setTimeout(() => {
          this.doctypegroupddselectedvalue = metadata?.mcsDoctypeGroupDD?.find(option => option?.value === metadata?.mcsDoctypeGroup?.value);
          this.doctypeddselectedvalue = metadata?.mcsDoctypeDD?.find(option => option?.value === metadata?.mcsDoctype?.value);
          this.docdescriptionddselectedvalue = metadata?.mcsDoctypeDescDD?.find(option => option?.value === metadata?.mcsDoctypeDesc?.value);
          this.secdescriptionddselectedvalue = metadata?.mcsDoctype2ndDescDD?.find(option => option?.value === metadata?.mcsDoctype2ndDesc?.value);
          if (this.openWorkflowPopup) {
            this.workflowselectedvalue = metadata?.workflowDD?.find(option => option?.value === metadata?.mcsHierarchyWorkflowId) ?? { name: "", value: "" };
          }
        }, 300);
        this.rollforwardselectedvalue = { "name": `${metadata?.mcsRollforwardName}`, "value": `${metadata?.mcsRollforward}` };
      }
      else {
        this.applicabletaxyear = null;
        this.selectedYears = [];
        // HOW TO PROCEED EG - MULTI UPLOAD ENGAGEMENT LEVEL, WE HAVE TO CLEAR WORKFLOWTAXYEAR VALUE FROM UI? -done
        if (!this.engagementTaxYearDuplicate) {
          this.engagementTaxYear = "";
        }
        if (!this.workflowTaxYearDuplicate) {
          this.workflowTaxYear = "";
        }
        this.selectedTaxYear = { name: "", code: "" };
        this.userdefineddescription = null;
        this.workflowselectedvalue = { name: "", value: "" };
        this.doctypegroupddselectedvalue = this.docTypeGroupDD?.length > 0 ? this.docTypeGroupDD[0] : null;
        this.doctypeddselectedvalue = null;
        this.docdescriptionddselectedvalue = null;
        this.secdescriptionddselectedvalue = null;
        this.rollforwardselectedvalue = { "name": "No", "value": "861570001" };

        let document = new Documents();
        document.mcsName = this.selectedFileName;
        document.mcsHierarchyClientId = this.clientId;
        document.mcsHierarchyEngagementId = this.engagementId;
        document.mcsHierarchyTaxpayerId = this.taxpayerId;
        document.mcsHierarchyWorkflowId = this.workflowId;
        document.mcsOwner = this.parsedContext.owner;

        if (this.engagementTaxYear) {
          document.mcsEngagementTaxYear = this.engagementTaxYear;
        }

        if (this.workflowTaxYear) {
          document.mcsWorkflowTaxYear = this.workflowTaxYear;
        }

        if (this.isAtTaxpayerLevel) {
          document.selectedTaxYear = this.selectedTaxYear.name;
        }
        else {
          document.selectedTaxYear = "";
        }

        document.userEmailId = this.username;
        document.mcsDoctypeGroupDD = this.docTypeGroupDD;
        if (this.docTypeGroupId && this.docTypeGroup) {
          this.doctypegroupddselectedvalue = { "name": this.docTypeGroup, "value": this.docTypeGroupId, "isActive": true };
        }

        document.mcsDoctypeDD = this.docTypeDD;
        if (this.docTypeId && this.docType) {
          this.doctypeddselectedvalue = { "name": this.docType, "value": this.docTypeId, "isActive": true };
        }

        if (this.doctypegroupddselectedvalue) {
          this.isatdoctypegroupnode = true;
          this.loadDocTypeFromTypeGroup(this.doctypegroupddselectedvalue.value);
          document.mcsDoctypeGroupName = this.doctypegroupddselectedvalue?.name;
          document.mcsDoctypeGroup = this.doctypegroupddselectedvalue;
        }

        if (this.docTypeId) {
          this.isatdoctypenode = true;
          this.loadDocDescriptionFromType(this.docTypeId);
        }
        if (this.doctypeddselectedvalue) {
          document.mcsDoctypeName = this.doctypeddselectedvalue?.name;
          document.mcsDoctype = this.doctypeddselectedvalue;
        }
        if (this.docdescriptionddselectedvalue) {
          document.mcsDoctypeDescName = this.docdescriptionddselectedvalue?.name;
          document.mcsDoctypeDesc = this.docdescriptionddselectedvalue?.value;
        }
        if (this.secdescriptionddselectedvalue) {
          document.mcsDoctype2ndDescName = this.secdescriptionddselectedvalue?.name;
          document.mcsDoctype2ndDesc = this.secdescriptionddselectedvalue?.value;
        }
        if (this.rollforwardselectedvalue) {
          document.mcsRollforwardName = this.rollforwardselectedvalue?.name;
          document.mcsRollforward = this.rollforwardselectedvalue?.value;
        }

        document.mcsApplicableTaxYear = this.applicabletaxyear;
        document.mcsUserDefinedDescription = this.userdefineddescription;

        this.tempMetadatas.push(document);
      }
    }

    this.uploadValidation();
  }


  metadataChanged(event: any, fieldName: string) {

    let metadata = this.tempMetadatas.find(x => x.mcsName === this.selectedFileName);

    if (metadata) {
      const clearDocType2 = () => {
        this.secdescriptionddselectedvalue = "";
        metadata!.mcsDoctype2ndDesc = undefined;
        metadata!.mcsDoctype2ndDescName = undefined;
      }
      const clearDocTypeDesc = () => {
        this.docdescriptionddselectedvalue = "";
        metadata!.mcsDoctypeDesc = undefined;
        metadata!.mcsDoctypeDescName = undefined;
        clearDocType2();
      }
      const clearDocType = () => {
        this.doctypeddselectedvalue = "";
        metadata!.mcsDoctype = undefined;
        metadata!.mcsDoctypeName = undefined;
        clearDocTypeDesc();
      }

      switch (fieldName) {
        case 'taxyear':
          metadata.selectedTaxYear = this.selectedTaxYear.name;
          metadata.mcsWorkflowTaxYear = this.selectedTaxYear.name;
          break;
        case 'workflow':
          metadata.mcsHierarchyWorkflowId = event?.value?.value;
          clearDocType();
          if (this.workflowselectedvalue) {
            if (this.workflowselectedvalue.name.includes(this.engagementManagementLabel)) {
              this.docTypeDD = this.docTypeDD.filter((x: { name: any }) => x.name.includes(this.projectManagementLabel));
            }
            else {
              this.loadDocTypeFromTypeGroup(metadata.mcsDoctypeGroup.value);
            }
            //fetch taxyear for selected Workflow and make tax year read-only
            this.documentsService.getWorkflowTaxYear(this.workflowselectedvalue.value).subscribe({
              next: (response) => {
                this.workflowTaxYear = response;
                this.taxyeardisabled = true;
                if (metadata && this.workflowTaxYear) {
                  metadata.mcsWorkflowTaxYear = this.workflowTaxYear;
                }
              },
              error: (error) => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
                console.error(error);
              }
            });
            metadata.workflowDD = this.workflowDD;
            metadata.mcsHierarchyWorkflowId = this.workflowselectedvalue.value;
          }
          break;
        case 'applicationtaxyear':
          this.applicabletaxyear = this.selectedYears.map(item => item.name).join(',');
          metadata.mcsApplicableTaxYear = this.applicabletaxyear;
          break;
        case 'userdefineddescription':
          metadata.mcsUserDefinedDescription = event;
          break;
        case 'doctypegroup':
          clearDocType();
          this.loadDocTypeFromTypeGroup(event.value.value);
          metadata.mcsDoctypeGroup = event.value;
          metadata.mcsDoctypeGroupName = event.name;
          metadata.mcsDoctypeGroupDD = this.docTypeGroupDD;
          break;
        case 'doctype':
          clearDocTypeDesc();
          this.loadDocDescriptionFromType(event.value.value);
          metadata.mcsDoctype = event.value;
          metadata.mcsDoctypeName = event.value?.name;
          metadata.mcsDoctypeDD = this.docTypeDD;
          break;
        case 'docdescription':
          clearDocType2();
          this.loadSecDescFromDocDesc(event.value.value);
          metadata.mcsDoctypeDesc = event.value;
          metadata.mcsDoctypeDescName = event.value?.name;
          metadata.mcsDoctypeDescDD = this.docDescriptionDD;
          break;
        case 'secdescription':
          metadata.mcsDoctype2ndDesc = event.value;
          metadata.mcsDoctype2ndDescName = event.value?.name;
          metadata.mcsDoctype2ndDescDD = this.secDescriptionDD;
          break;
        case 'rollforward':
          metadata.mcsRollforward = event?.value?.value;
          metadata.mcsRollforwardName = event?.value?.name;
          break;
      }

      if (this.copymetadata) {
        this.updateCopiedMetaData(metadata);
      }
    }

    this.uploadValidation();
  }

  async metadataChangedForEdit(event: any, fieldName: string) {
    const value: string | undefined = event?.value;
    let metadata = new MetadataUpdate();

    const clearDocType2 = () => {
      this.getMetadata.doctype2ndDescs = [];
    }
    const clearDocTypeDesc = () => {
      this.getMetadata.doctypeDescs = [];
      clearDocType2();
    }
    const clearDocType = () => {
      this.getMetadata.doctypes = [];
      clearDocTypeDesc();
    }
    const clearDocTypeGroup = () => {
      this.getMetadata.doctypeGroups = [];
      clearDocType();
    }
    const clearWorkflow = () => {
      this.getMetadata.workflows = [];
      clearDocTypeGroup();
    }
    const clearEngagement = () => {
      this.getMetadata.engagements = [];
      clearWorkflow();
    }

    if (metadata) {
      switch (fieldName) {
        case 'taxpayer':
          metadata.taxpayerId = value;
          clearEngagement();
          if (this.disableEngagement === true && this.disableWorkflow === true) {
            this.loadDocTypeGroupFromWorkflow("");      // Taxpayer level Doc type group.    
          } else {
            this.loadEngagementFromTaxpayer(value);
          }
          break;
        case 'engagement':
          metadata.engagementId = value;
          clearWorkflow();
          await this.setWorkflowDropdownOptionalAndReadonly(metadata.engagementId);

          this.selectedTaxYearForEdit = { name: "", code: "" };
          if (!this.workflowDropdownOptionalAndReadonly) {
            this.loadWorkflowFromEngagement(value, this.getMetadata?.selectedTaxpayer?.value ?? "");
          }
          this.documentsService.getEngagementTaxYear(value).subscribe({
            next: (response) => {
              this.getMetadata.engagementTaxYear = response;
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
              console.error(error);
            }
          });
          break;
        case 'workflow':
          if (this.workflowDropdownOptionalAndReadonly) return;
          metadata.workflowId = event?.value;
          clearDocTypeGroup();
          this.loadDocTypeGroupFromWorkflow(this.getMetadata.selectedEngagement?.value);

          this.documentsService.getWorkflowTaxYear(value).subscribe({
            next: (response) => {
              this.getMetadata.taxYear = response;
              this.selectedTaxYearForEdit = this.years.filter((year: any) => year.name == this.getMetadata.taxYear)[0];
              this.taxyeardisabled = true;
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
              console.error(error);
            }
          });
          break;
        case 'doctypegroup':
          metadata.doctypeGroupId = value;
          clearDocType();
          this.loadDoctypeFromDoctypeGroupForEdit(value);
          break;
        case 'doctype':
          metadata.doctypeId = value;
          clearDocTypeDesc();
          this.loadDoctypeDescFromDoctypeForEdit(value);
          break;
        case 'docdescription':
          metadata.doctypeDescId = value;
          clearDocType2();
          this.loadDoctype2ndDescFromDoctypeDescForEdit(value);
          break;
        case 'secdescription':
          metadata.doctype2ndDescId = value;
          break;
        case 'userdefineddescription':
          metadata.userDefineDescription = event;
          break;
        case 'applicabletaxyear':
          metadata.applicableTaxYear = this.selectedYearsForEdit.map(item => item.name).join(',');
          this.getMetadata.applicableTaxYear = metadata.applicableTaxYear;
          break;
        case 'taxyear':
          metadata.taxYear = this.selectedTaxYearForEdit?.name;
          this.getMetadata.taxYear = metadata.taxYear;
          break;
      }
      this.updateValidation();
    }
  }

  // DD for edit
  // use getupdatemetadata new function
  loadEngagementFromTaxpayer(taxpayerId: string | undefined) {
    if (!taxpayerId)
      return;

    this.documentsService.getUpdateMetadata(taxpayerId, Entities[Entities.mcs_engagement], this.clientId, "").subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.engagements = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for edit
  loadWorkflowFromEngagement(engagementId: string | undefined, taxpayerId: string) {
    if (!engagementId)
      return;

    this.documentsService.getUpdateMetadata(engagementId, Entities[Entities.msdyn_project], this.clientId, taxpayerId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.workflows = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for edit
  loadDocTypeGroupFromWorkflow(engagementId: any) {
    this.documentsService.getDocTypeGroup(engagementId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctypeGroups = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for edit
  loadDoctypeFromDoctypeGroupForEdit(doctypeGroupId: string | undefined) {
    if (!doctypeGroupId)
      return;

    this.documentsService.getUpdateMetadata(doctypeGroupId, Entities[Entities.mcs_doctypetodtgmapping], this.clientId, "").subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        if (this.getMetadata.selectedWorkflow?.name.includes(this.engagementManagementLabel) === true) {
          this.getMetadata.doctypes = sortedresponse.filter((x: { name: any }) => x.name.includes(this.projectManagementLabel));
        }
        else {
          this.getMetadata.doctypes = sortedresponse;
        }
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }


  //DD for upload
  loadDoctypeDescFromDoctype(doctypeId: string) {
    this.documentsService.getMetadata(doctypeId, Entities[Entities.mcs_docdesctodttodtgmapping], this.clientId, "").subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctypeDescs = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for edit
  loadDoctypeDescFromDoctypeForEdit(doctypeId: string | undefined) {
    if (!doctypeId)
      return;

    this.documentsService.getUpdateMetadata(doctypeId, Entities[Entities.mcs_docdesctodttodtgmapping], this.clientId, "").subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctypeDescs = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for edit
  loadDoctype2ndDescFromDoctypeDescForEdit(doctypeDescId: string | undefined) {
    if (!doctypeDescId)
      return;

    this.documentsService.getUpdateMetadata(doctypeDescId, Entities[Entities.mcs_secdesctoddtodttodtgmapping], this.clientId, "").subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.getMetadata.doctype2ndDescs = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for upload
  loadSecDescFromDocDesc(guid: string) {
    this.documentsService.getMetadata(guid, Entities[Entities.mcs_secdesctoddtodttodtgmapping], this.clientId, this.taxpayerId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.secDescriptionDD = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for upload
  loadDocDescriptionFromType(guid: string) {
    this.documentsService.getMetadata(guid, Entities[Entities.mcs_docdesctodttodtgmapping], this.clientId, this.taxpayerId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.docDescriptionDD = sortedresponse;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // DD for upload
  loadDocTypeFromTypeGroup(guid: string) {
    this.documentsService.getMetadata(guid, Entities[Entities.mcs_doctypetodtgmapping], this.clientId, this.taxpayerId).subscribe({
      next: (response) => {
        let sortedresponse = response?.sort((a: any, b: any) => a.name.localeCompare(b.name));

        // CHECK IF UPLOAD IS AT TAXPAYER LEVEL, IF YES, CHECK FOR ASSOCIATED ENGAGEMENT IS THERE, IF YES, REMOVE PENDING
        if (this.taxpayer && !this.engagement && !this.workflow) {
          this.documentsService.checkIfTaxpayerHasAssociatedEngagement(this.taxpayerId).subscribe({
            next: (response) => {
              if (response == true) {
                let newsortedresponse: Dropdown[] = [];
                for (let dropdown of sortedresponse) {
                  if (!dropdown.name.includes("Pending")) {
                    newsortedresponse.push(dropdown);
                  }
                }
                sortedresponse = newsortedresponse;
              }
              this.docTypeDD = sortedresponse;
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
              console.error(error);
            },
          });
        }
        else {
          const selectedWorkflow = this.workflow || this.workflowselectedvalue?.name;
          if (selectedWorkflow?.includes(this.engagementManagementLabel) === true) {
            this.docTypeDD = sortedresponse.filter((x: { name: any }) => x.name.includes(this.projectManagementLabel));
          }
          else {
            this.docTypeDD = sortedresponse;
          }
        }
        let metadata = this.tempMetadatas.find(x => x.mcsName === this.selectedFileName);
        if (metadata) {
          metadata.mcsDoctypeDD = this.docTypeDD;
        }
      },
      error: (error) => {
        //this.messageService.add({ severity: 'error', summary: error.name, detail: error.error });
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  showCopyMetaDataCheckBox(): any {
    return this.uploadedFiles?.length > 1 && this.selectedFileName;
  }

  uploadValidation(): boolean {
    if (!this.tempMetadatas || this.tempMetadatas.length === 0 || this.uploadedFiles.length === 0) {
      return true;
    }

    if (this.tempMetadatas.length !== this.uploadedFiles.length) {
      return true;
    }

    if (this.isUploading) {
      return true;
    }

    if (this.isUploadDisabled) {
      return true;
    }

    if (!this.isWorkflowValidationPassing(this.workflowselectedvalue.value)
      && this.openWorkflowPopup && !this.isApplicableTaxYearRequired) {
      return true;
    }

    if (this.isApplicableTaxYearRequired && this.selectedYears.length <= 0) {
      return true;
    }

    if (this.isAtTaxpayerLevel && !this.selectedTaxYear.code) {
      return true;
    }

    if (this.client && !this.taxpayer && !this.engagement && !this.workflow) {
      return true;
    }

    for (const metadata of this.tempMetadatas) {
      if (!metadata.mcsDoctypeGroup || !metadata.mcsDoctype || !metadata.mcsDoctypeDesc) {
        return true;
      }
    }

    return false;
  }

  private getUploadMetadata(metadata: any) {
    let document = new Documents();

    if (metadata) {
      document.mcsName = metadata.mcsName;
      document.mcsOwner = metadata.mcsOwner;
      document.userEmailId = metadata.userEmailId;

      document.mcsHierarchyClientId = metadata.mcsHierarchyClientId;
      document.mcsHierarchyTaxpayerId = metadata.mcsHierarchyTaxpayerId;
      document.mcsHierarchyEngagementId = metadata.mcsHierarchyEngagementId;
      document.mcsHierarchyWorkflowId = metadata.mcsHierarchyWorkflowId;

      document.mcsDoctypeGroup = metadata.mcsDoctypeGroup?.value;
      document.mcsDoctypeGroupName = metadata.mcsDoctypeGroup?.name;
      document.mcsDoctype = metadata.mcsDoctype?.value;
      document.mcsDoctypeName = metadata.mcsDoctype?.name;
      document.mcsDoctypeDesc = metadata.mcsDoctypeDesc?.value;
      document.mcsDoctypeDescName = metadata.mcsDoctypeDesc?.name;
      document.mcsDoctype2ndDescName = metadata.mcsDoctype2ndDesc?.name;
      document.mcsDoctype2ndDesc = metadata.mcsDoctype2ndDesc?.value;
      document.mcsUserDefinedDescription = metadata.mcsUserDefinedDescription;


      document.mcsEngagementTaxYear = metadata.mcsEngagementTaxYear;
      document.mcsWorkflowTaxYear = metadata.mcsWorkflowTaxYear;
      document.mcsApplicableTaxYear = metadata.mcsApplicableTaxYear;

      document.mcsRollforward = metadata.mcsRollforward;
      document.mcsRollforwardName = metadata.mcsRollforwardName;
    }

    return document;
  }

  public uploadChunkedDocument() {
    if (!this.uploadValidation()) {
      this.isUploading = true;
      this.filesUploaded = 0;
      let formDatas: FormData[] = [];

      this.uploadedFiles.forEach((file: File) => {
        let formData = new FormData();
        const metadata = this.tempMetadatas.find(x => x.mcsName === file.name);

        const documentMetadata = JSON.stringify(this.getUploadMetadata(metadata));

        if (file.size > 450 * 1024) {
          var chunkSize = 450 * 1024 * 1024;
          var totalChunks = Math.ceil(file.size / chunkSize);
          var currentChunk = 0;
          var documentId = '';

          const uploadNextChunk = () => {
            const start = currentChunk * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);
            let formData = new FormData();
            formData.append("metadata", documentMetadata);
            formData.append("file", new File([chunk], file.name));
            formData.append("isChunked", "1");
            formData.append("currentIndex", currentChunk.toString());
            formData.append("chunkedLength", totalChunks.toString());
            formData.append("documentId", documentId);

            this.uploadService.create(formData).subscribe({
              next: (response) => {
                currentChunk++;
                documentId = response.mcsDocumentId;
                if (currentChunk < totalChunks) {
                  uploadNextChunk();
                }
                else {
                  this.filesUploaded++;
                  if (this.filesUploaded == this.uploadedFiles.length) {
                    this.isUploading = false;
                    this.onCancel(null);
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Saved successfully' });
                    this.commonService.refreshTreeOnOperationComplete(true);
                    this.commonService.refreshOnOperationComplete(true);
                  }
                }
              },
              error: (error) => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedUpload });
                this.isUploading = false;
                console.error(error);
              }
            });
          };

          uploadNextChunk();
        } else {
          formData.append("metadata", documentMetadata);
          formData.append("file", file);
          formData.append("isChunked", "0");
          formData.append("currentIndex", "0");
          formData.append("chunkedLength", "0");
          formData.append("documentId", "");
          formDatas.push(formData);
        }
      });

      const requests = formDatas.map(document =>
        of(document).pipe(
          mergeMap(doc => this.uploadService.create(doc))
        )
      );

      forkJoin(requests).subscribe({
        next: (responses) => {
          console.log(responses);
          this.filesUploaded += requests.length;
          if (this.filesUploaded == this.uploadedFiles.length) {
            this.isUploading = false;
            this.onCancel(null);
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Saved successfully' });
            this.commonService.refreshTreeOnOperationComplete(true);
            this.commonService.refreshOnOperationComplete(true);
          }
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedUpload });
          this.isUploading = false;
          console.error(error);
        }
      });
    }
  }

  private attachMetadeta() {
    //let formDatas: FormData[] = [];

    //this.uploadedFiles.forEach((file: File) => {
    //  let formData = new FormData();
    //  let metadata = this.tempMetadatas.find(x => x.mcsName === file.name);

    //  metadata!.mcsDoctypeGroupName = metadata?.mcsDoctypeGroup?.name;
    //  metadata!.mcsDoctypeName = metadata?.mcsDoctype?.name;
    //  metadata!.mcsDoctypeDescName = metadata?.mcsDoctypeDesc?.name;
    //  metadata!.mcsDoctype2ndDescName = metadata?.mcsDoctype2ndDesc?.name;

    //  metadata!.mcsDoctypeGroup = metadata?.mcsDoctypeGroup?.value;
    //  metadata!.mcsDoctype = metadata?.mcsDoctype?.value;
    //  metadata!.mcsDoctypeDesc = metadata?.mcsDoctypeDesc?.value;
    //  metadata!.mcsDoctype2ndDesc = metadata?.mcsDoctype2ndDesc?.value;

    //  var documentMetadata = JSON.stringify(metadata);

    //  if (file.size >= 150 * 1024 ) {

    //    var chunkSize = 100 * 1024 * 1024;
    //    var fileSize = file.size;
    //    var chunks = Math.ceil(file.size / chunkSize);
    //    var chunk = 0;

    //    console.log('file size..', fileSize);
    //    console.log('chunks...', chunks);

    //    while (chunk < chunks) {
    //      var offset = chunk * chunkSize;
    //      console.log('current chunk..', chunk);
    //      console.log('offset...', chunk * chunkSize);
    //      console.log('file blob from offset...', offset)
    //      let slicedFile = file.slice(offset, offset + chunkSize);
    //      console.log('sliced file...', slicedFile);
    //      formData.append("metadata", documentMetadata);
    //      formData.append("file", new File([slicedFile], file.name));
    //      formData.append("isChunked", "1");
    //      formData.append("currentIndex", chunk.toString());
    //      formData.append("chunkedLength", chunks.toString());
    //      formDatas.push(formData);
    //      chunk++;
    //    }

    //    //let chunkSize = 400000000;
    //    //let chunkLength = Math.round(file.length / chunkSize);

    //    //for (let i = 0; i < chunkLength; i++) {

    //    //}
    //  } else {
    //    formData.append("metadata", documentMetadata);
    //    formData.append("file", file);
    //    formData.append("isChunked", "0");
    //    formData.append("currentIndex", "0");
    //    formData.append("chunkedLength", "0");
    //    formDatas.push(formData);
    //  }
    //});

    //return formDatas;
  }

  updateCopiedMetaData(metadata: any) {
    var remFiles = this.tempMetadatas.filter(x => x.mcsName !== metadata.mcsName);
    remFiles.forEach(x => {
      x.mcsHierarchyClientId = metadata!.mcsHierarchyClientId;
      x.mcsHierarchyEngagementId = metadata!.mcsHierarchyEngagementId;
      x.mcsHierarchyTaxpayerId = metadata!.mcsHierarchyTaxpayerId;
      x.mcsHierarchyWorkflowId = metadata!.mcsHierarchyWorkflowId;
      x.userEmailId = metadata!.userEmailId;

      x.mcsDoctypeGroup = metadata!.mcsDoctypeGroup;
      x.mcsDoctypeGroupName = metadata!.mcsDoctypeGroupName;
      x.mcsDoctypeGroupDD = metadata!.mcsDoctypeGroupDD;

      x.mcsDoctype = metadata!.mcsDoctype;
      x.mcsDoctypeName = metadata!.mcsDoctypeName;
      x.mcsDoctypeDD = metadata!.mcsDoctypeDD

      x.mcsDoctypeDesc = metadata!.mcsDoctypeDesc;
      x.mcsDoctypeDescName = metadata!.mcsDoctypeDescName;
      x.mcsDoctypeDescDD = metadata!.mcsDoctypeDescDD;

      x.mcsDoctype2ndDesc = metadata!.mcsDoctype2ndDesc;
      x.mcsDoctype2ndDescName = metadata!.mcsDoctype2ndDescName;
      x.mcsDoctype2ndDescDD = metadata!.mcsDoctype2ndDescDD;

      // CHECK IF mcsEngagementTaxYear OR mcsEngagementTaxYearDUPLICATE SHOULD BE USED
      x.mcsEngagementTaxYear = metadata!.mcsEngagementTaxYear;
      x.mcsWorkflowTaxYear = metadata!.mcsWorkflowTaxYear;
      // ADD TAXPAYER LEVEL TAXYEAR ALSO
      if (this.isAtTaxpayerLevel) {
        x.selectedTaxYear = metadata!.selectedTaxYear;
      }
      x.mcsApplicableTaxYear = metadata!.mcsApplicableTaxYear;
      x.mcsUserDefinedDescription = metadata!.mcsUserDefinedDescription;
      x.mcsRollforward = metadata!.mcsRollforward;
      x.mcsRollforwardName = metadata!.mcsRollforwardName;
      if (this.openWorkflowPopup) {
        x.workflowDD = metadata!.workflowDD;
      }

    })
  }

  copyMetadataToUploadDocuments() {
    this.copymetadata = !this.copymetadata;
    if (this.copymetadata && this.selectedFileName) {
      let metadata = this.tempMetadatas.find(x => x.mcsName === this.selectedFileName);
      if (metadata) {
        var remFiles = this.uploadedFiles.filter(x => !this.tempMetadatas.find(y => y.mcsName === x.name));

        if (remFiles.length > 0) {
          remFiles.forEach(x => {
            let document = new Documents();
            document.mcsName = x.name;
            document.mcsHierarchyClientId = metadata!.mcsHierarchyClientId;
            document.mcsHierarchyEngagementId = metadata!.mcsHierarchyEngagementId;
            document.mcsHierarchyTaxpayerId = metadata!.mcsHierarchyTaxpayerId;
            document.mcsHierarchyWorkflowId = metadata!.mcsHierarchyWorkflowId;
            document.userEmailId = metadata!.userEmailId;

            document.mcsDoctypeGroup = metadata!.mcsDoctypeGroup;
            document.mcsDoctypeGroupName = metadata!.mcsDoctypeGroupName;
            document.mcsDoctypeGroupDD = metadata!.mcsDoctypeGroupDD;

            document.mcsDoctype = metadata!.mcsDoctype;
            document.mcsDoctypeName = metadata!.mcsDoctypeName;
            document.mcsDoctypeDD = metadata!.mcsDoctypeDD

            document.mcsDoctypeDesc = metadata!.mcsDoctypeDesc;
            document.mcsDoctypeDescName = metadata!.mcsDoctypeDescName;
            document.mcsDoctypeDescDD = metadata!.mcsDoctypeDescDD;

            document.mcsDoctype2ndDesc = metadata!.mcsDoctype2ndDesc;
            document.mcsDoctype2ndDescName = metadata!.mcsDoctype2ndDescName;
            document.mcsDoctype2ndDescDD = metadata!.mcsDoctype2ndDescDD;

            document.mcsEngagementTaxYear = metadata!.mcsEngagementTaxYear;
            document.mcsWorkflowTaxYear = metadata!.mcsWorkflowTaxYear;
            // ADD TAXYEAR FOR TAXPAYER DOC
            if (this.isAtTaxpayerLevel) {
              document.selectedTaxYear = metadata!.selectedTaxYear;
            }
            document.mcsApplicableTaxYear = metadata!.mcsApplicableTaxYear;
            document.mcsUserDefinedDescription = metadata!.mcsUserDefinedDescription;
            document.mcsRollforward = metadata!.mcsRollforward;
            document.mcsRollforwardName = metadata!.mcsRollforwardName;
            if (this.openWorkflowPopup) {
              document.workflowDD = metadata!.workflowDD;
            }

            this.tempMetadatas.push(document!);
          });
        }
        else {
          this.updateCopiedMetaData(metadata);
        }
      }
    }
  }

  uploadDocuments() {
    this.uploadChunkedDocument();
  }

  selectedRowsSubscription() {
    this.commonService.matrixRowSelectEvent.subscribe((data: SignOffMatrix[]) => {
      this.selectedDocuments = data;
      this.buttonsEnableOnRowSelect();
    });
  }

  onKeypress(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    const input = event.target as HTMLInputElement;
    const inputValue = input.value + event.key;
    if (isNaN(Number(event.key)) || inputValue.length > 4) {
      event.preventDefault();
    }

  }

  getFileClass(fileName: string): string {
    if (fileName.includes('pdf')) {
      return 'pi pi-file-pdf';
    } else if (fileName.includes('excel') || fileName.includes('xlsx')) {
      return 'pi pi-file-excel';
    } else if (fileName.includes('word') || fileName.includes('docx')) {
      return 'pi pi-file-word';
    } else {
      return 'pi pi-file';
    }
  }

  getDeleteDocumentRequest() {
    let documents: DeleteDocuments[] = [];
    this.selectedDocuments.forEach(x => {
      const documentRequest: DeleteDocuments = {
        documentId: x.key,
        statusReason: x.statuscode
      }
      documents.push(documentRequest);
    });
    let documentData: DeleteDocumentRequest = {
      userId: this.userId,
      documents: documents
    };
    return documentData;
  }


  deleteRows() {
    if (!this.isDeleteAllowed || this.deletedisabled) {
      return;
    }
    let deleteRequest = this.getDeleteDocumentRequest();
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete?',
      accept: () => {
        this.documentsService.deleteRows(deleteRequest)
          .subscribe({
            next: (response) => {
              this.commonService.drawerClicked(true);
              this.drawerValue = true;
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Deleted successfully' });
              this.commonService.refreshOnOperationComplete(true);
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedDelete });
              console.error(error);
            }
          });
      },
      reject: () => {

      }
    });
  }

  archiveDocuments() {
    const keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
    this.documentsService.archiveRows(keyListSelectedDocs)
      .subscribe({
        next: (response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Archival initiated' });
          this.commonService.refreshOnOperationComplete(true);
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
          console.error(error);
        }
      });
  }

  unarchiveDocuments() {
    const keyListSelectedDocs: string[] = this.selectedDocuments.map(x => x.key);
    const data: getRolesDTO = new getRolesDTO();
    data.selectedDocuments = keyListSelectedDocs;
    data.username = this.userId;
    this.documentsService.unarchiveRows(data)
      .subscribe({
        next: (response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Unarchival initiated' });
          this.commonService.refreshOnOperationComplete(true);
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
          console.error(error);
        }
      });
  }

  // Get hold and unhold Document data.
  getDocumentData() {
    let documentData: HoldUnholdDocument[] = [];
    this.selectedDocuments.forEach(x => {
      const documentRequest: HoldUnholdDocument = {
        dynamicsDocumentID: x.key,
        documentURL: x.url
      }
      documentData.push(documentRequest);
    });

    return documentData;
  }

  // Methods to put documents on hold.
  holdDocuments() {
    let isValidDocument = this.selectedDocuments.find(x => x.statuscode == 'Archived');
    if (isValidDocument !== undefined) {
      this.messageService.add({ severity: 'warn', summary: 'Hold Document(s)', detail: messages.archivedValidationError });
      return;
    }

    const request: HoldUnholdRequest = {
      clientId: this.clientId,
      documents: this.getDocumentData()
    };

    this.documentsService.holdDocuments(request).subscribe({
      next: (response) => {
        this.messageService.add({ severity: 'info', summary: messages.holdDocuments, detail: messages.holdDocumentsTriggered });
        this.commonService.refreshOnOperationComplete(true);
      }, error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
        console.error(error);
        //this.messageService.add({ severity: 'error', summary: messages.holdDocuments, detail: error });
      }
    });
  }

  // Method to remove documents from hold.
  unholdDocuments() {
    let isValidDocument = this.selectedDocuments.find(x => x.statuscode == 'Archived');
    if (isValidDocument !== undefined) {
      this.messageService.add({ severity: 'warn', summary: 'Unhold Document(s)', detail: messages.archivedValidationError });
      return;
    }

    const request: HoldUnholdRequest = {
      clientId: this.clientId,
      documents: this.getDocumentData()
    };

    this.documentsService.unholdDocuments(request).subscribe({
      next: (response) => {
        this.messageService.add({ severity: 'info', summary: messages.unholdDocuments, detail: messages.unholdDocumentsTriggered });
        this.commonService.refreshOnOperationComplete(true);
      }, error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.errorOccurred });
        console.error(error);
        //this.messageService.add({ severity: 'error', summary: messages.unholdDocuments, detail: error });
      }
    });
  }

  goToRecoverScreen() {
    if (!this.isRecoverAllowed || this.recoverDisabled) {
      return;
    }
    const newTabUrl: string = '/recover';
    const url = this.router.serializeUrl(this.router.createUrlTree([newTabUrl]));
    window.open(url, '_blank');
    localStorage.setItem('userId', this.userId);
  }


  private updateMetadata_Taxpayers(response: GetDocumentMetadata) {
    this.getMetadata.taxpayers = response.taxpayers ?? null;
    //sort in alphabetical order
    this.getMetadata.taxpayers = this.getMetadata.taxpayers?.sort((a: any, b: any) => a.name.localeCompare(b.name));
    this.getMetadata.taxpayerId = response.taxpayerId;
    if (this.getMetadata.taxpayers != null && this.getMetadata.taxpayerId != null) {
      this.getMetadata.selectedTaxpayer = this.getMetadata.taxpayers.find(option => option.value === this.getMetadata.taxpayerId);
    }
  }

  private async updateMetadata_Engagements(response: GetDocumentMetadata) {
    this.getMetadata.engagements = response.engagements ?? null;
    this.getMetadata.engagements = this.getMetadata.engagements?.sort((a: any, b: any) => a.name?.localeCompare(b.name));
    this.getMetadata.engagementId = response.engagementId;
    if (this.getMetadata.engagements != null && this.getMetadata.engagementId != null) {
      this.getMetadata.selectedEngagement = this.getMetadata.engagements.find(option => option.value === this.getMetadata.engagementId);
      await this.setWorkflowDropdownOptionalAndReadonly(this.getMetadata.selectedEngagement?.value);
    }
  }

  private updateMetadata_Workflows(response: GetDocumentMetadata) {
    this.getMetadata.workflows = response.workflows ?? null;
    this.getMetadata.workflows = this.getMetadata.workflows?.sort((a: any, b: any) => a.name.localeCompare(b.name));
    this.getMetadata.workflowId = response.workflowId;
    if (this.getMetadata.workflows != null && this.getMetadata.workflowId != null) {
      this.getMetadata.selectedWorkflow = this.getMetadata.workflows.find(option => option.value === this.getMetadata.workflowId);
    }
  }

  private updateMetadata_DocTypeGroups(response: GetDocumentMetadata) {
    this.getMetadata.doctypeGroups = response.doctypeGroups ?? null;
    this.getMetadata.doctypeGroups = this.getMetadata.doctypeGroups?.sort((a: any, b: any) => a.name.localeCompare(b.name));
    this.getMetadata.doctypeGroupId = response.doctypeGroupId;
    if (this.getMetadata.doctypeGroups != null && this.getMetadata.doctypeGroupId != null) {
      this.getMetadata.selectedDoctypeGroup = this.getMetadata.doctypeGroups.find(option => option.value === this.getMetadata.doctypeGroupId);
    }
  }

  private updateMetadata_DocTypes(response: GetDocumentMetadata) {
    this.getMetadata.doctypes = response.doctypes ?? null;
    this.getMetadata.doctypes = this.getMetadata.doctypes?.sort((a: any, b: any) => a.name.localeCompare(b.name));
    if (this.getMetadata.selectedWorkflow?.name.includes(this.engagementManagementLabel)) {
      this.getMetadata.doctypes = this.getMetadata.doctypes.filter((x: { name: any }) => x.name.includes(this.projectManagementLabel));
    }
    this.getMetadata.doctypeId = response.doctypeId
    if (this.getMetadata.doctypes != null && this.getMetadata.doctypeId != null) {
      this.getMetadata.selectedDoctype = this.getMetadata.doctypes.find(option => option.value === this.getMetadata.doctypeId);
    }
  }

  private updateMetadata_DocTypeDescs(response: GetDocumentMetadata) {
    this.getMetadata.doctypeDescs = response.doctypeDescs ?? null;
    this.getMetadata.doctypeDescs = this.getMetadata.doctypeDescs?.sort((a: any, b: any) => a.name.localeCompare(b.name));
    this.getMetadata.doctypeDescId = response.doctypeDescId;
    if (this.getMetadata.doctypeDescs != null && this.getMetadata.doctypeDescId != null) {
      this.getMetadata.selectedDoctypeDesc = this.getMetadata.doctypeDescs.find(option => option.value === this.getMetadata.doctypeDescId);
    }
  }

  private updateMetadata_DocTypeDescs2(response: GetDocumentMetadata) {
    this.getMetadata.doctype2ndDescs = response.doctype2ndDescs ?? null;
    this.getMetadata.doctype2ndDescs = this.getMetadata.doctype2ndDescs?.sort((a: any, b: any) => a.name.localeCompare(b.name));
    this.getMetadata.doctype2ndDescId = response.doctype2ndDescId;
    if (this.getMetadata.doctype2ndDescs != null && this.getMetadata.doctype2ndDescId != null) {
      this.getMetadata.selectedDoctype2ndDesc = this.getMetadata.doctype2ndDescs.find(option => option.value === this.getMetadata.doctype2ndDescId);
    }
  }

  private async updateMetadataFromRespones(response: GetDocumentMetadata) {
    this.updateMetadata_Taxpayers(response);
    await this.updateMetadata_Engagements(response);
    this.updateMetadata_Workflows(response);

    this.showUserPrompt = !this.getMetadata.engagementId && !this.getMetadata.workflowId;
    this.showTaxpayerReindexPrompt = this.getMetadata.workflowId ? true : false;
    this.updateMetadata_DocTypeGroups(response);
    this.updateMetadata_DocTypes(response);
    this.updateMetadata_DocTypeDescs(response);
    this.updateMetadata_DocTypeDescs(response);
    this.updateMetadata_DocTypeDescs2(response);

    this.getMetadata.userDefineDescription = response.userDefineDescription;
    this.getMetadata.applicableTaxYear = response.applicableTaxYear;

    if (this.getMetadata.applicableTaxYear) {
      const yearList = this.getMetadata.applicableTaxYear.split(",");
      this.selectedYearsForEdit = this.years.filter((year: any) => { return yearList.includes(year.name); });
    }

    this.getMetadata.taxYear = response.taxYear;
  }

  //TODO curry the above
  //private updateMetdata(
  //  response: GetDocumentMetadata,
  //  prop: keyof GetDocumentMetadata,
  //  propKey: keyof GetDocumentMetadata,
  //  selected: keyof GetDocumentMetadata,
  //  secondary: Promise<any> | undefined) {
  //  this.getMetadata[prop] = response[prop] ?? null;
  //  this.getMetadata.doctype2ndDescs = this.getMetadata.doctype2ndDescs?.sort((a: any, b: any) => a.name.localeCompare(b.name));
  //  this.getMetadata.doctype2ndDescId = response.doctype2ndDescId;
  //  if (this.getMetadata.doctype2ndDescs != null && this.getMetadata.doctype2ndDescId != null) {
  //    this.getMetadata.selectedDoctype2ndDesc = this.getMetadata.doctype2ndDescs.find(option => option.value === this.getMetadata.doctype2ndDescId);
  //  }
  //}

  //remove boolean.
  async editMetadata() {
    this.IsEditSidebarVisible = true;

    // get default values for getMetadata object and bind
    this.documentsService.getDocumentsById(this.selectedDocuments[0]?.key || '', this.clientId).subscribe({
      next: async (response) => {
        await this.updateMetadataFromRespones(response);

        if (this.getMetadata.taxYear) {
          this.selectedTaxYearForEdit = this.years.filter((year: any) => year.name == this.getMetadata.taxYear)[0];
        }

        this.taxyeardisabled = this.disableEngagement == true && this.disableWorkflow == true ? false : true;
        this.updateValidation();
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });

    this.commonService.disableRowSelectOnPanel(true);

  }

  openDocsInBrowser() {
    if (this.openInBrowserDisabled) {
      return;
    }

    for (let doc of this.selectedDocuments) {
      let browserParameter = "?&web=1";
      let modifiedUrl = doc.url + browserParameter;
      console.log(encodeURI(modifiedUrl));
      try {
        window.open(decodeURI(modifiedUrl), '_blank', 'noreferrer');
      }
      catch (error) {
        console.error(error);
      }
    }
    this.commonService.refreshOnOperationComplete(true)
  }

  getSignOffTooltip() {
    if (!this.validSignoffNode) {
      return messages.selectWorkflowNode;
    }
    else if (this.selectedDocuments.length == 0) {
      return messages.selectDocument;
    }
    else if (this.selectedDocuments.length > 1) {
      return "";
    }
    else if (this.selectedDocuments.length == 1 && !this.selectedDocuments[0]?.url) {
      return messages.waitForDocUpload;
    }
    else return ""
  }

  getEditTooltip() {
    if (this.selectedDocuments.length == 1) {
      if (this.selectedDocuments[0]?.retentionlabel && this.selectedDocuments[0]?.statuscode == 'Archived') {
        return "You can't edit this document. It is archived and has retention label applied";
      }
      else if (this.selectedDocuments[0]?.statuscode == 'Archived') {
        return "You can't edit this document. It is read-only";
      }
      else return "";
    }
    else if (this.selectedDocuments.length > 1) {
      return "Please select only one document for editing";
    }
    else return "";
  }

  updateValidation() {

    if (this.isUploading || this.isUpdating) {
      return true;
    }

    if (this.isApplicableTaxYearRequired && !this.getMetadata.applicableTaxYear) {
      return true;
    }

    if (!this.getMetadata.selectedTaxpayer || (!this.disableEngagement && !this.getMetadata.selectedEngagement)
      || !this.isWorkflowValidationPassing(this.getMetadata.selectedWorkflow)
      || !this.getMetadata.selectedDoctypeGroup || !this.getMetadata.selectedDoctype || !this.getMetadata.selectedDoctypeDesc
      || (this.isEditSecondaryDescriptionRequired && !this.getMetadata.selectedDoctype2ndDesc)
      //Normal vs PreBTU engagements, logic is not better understood than that the first value is not required because it is related to Workflow which is not required (and should not be able to be selected)
      || (!this.selectedTaxYearForEdit?.code && !this.workflowDropdownOptionalAndReadonly)) {
      return true;
    }

    return false;
  }

  getMetadataObject(): MetadataUpdate {
    let dto = new MetadataUpdate();
    dto.clientId = this.clientId;
    dto.documentId = this.selectedDocuments[0]?.key;
    dto.applicableTaxYear = this.getMetadata.applicableTaxYear;
    dto.engagementTaxYear = this.getMetadata.engagementTaxYear?.toString();
    dto.taxYear = this.getMetadata.taxYear?.toString();
    dto.doctype2ndDescId = this.getMetadata.selectedDoctype2ndDesc?.value;
    dto.doctypeDescId = this.getMetadata.selectedDoctypeDesc?.value;
    dto.doctypeGroupId = this.getMetadata.selectedDoctypeGroup?.value;
    dto.doctypeId = this.getMetadata.selectedDoctype?.value;
    dto.engagementId = this.getMetadata.selectedEngagement?.value;
    dto.taxpayerId = this.getMetadata.selectedTaxpayer?.value;
    dto.workflowId = this.getMetadata.selectedWorkflow?.value;
    dto.userDefineDescription = this.getMetadata.userDefineDescription;
    dto.userEmailId = this.username;
    return dto;
  }

  updateDocuments() {
    if (!this.updateValidation()) {
      this.isUpdating = true;
      this.updateValidation();
      let documents = this.getMetadataObject();
      console.log(documents);

      this.documentsService.updateDocuments(documents)
        .subscribe({
          next: (response) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: messages.updateSuccess });
            this.commonService.refreshTreeOnOperationComplete(true);
            this.commonService.refreshOnOperationComplete(true);
            this.isUpdating = false;
            this.IsEditSidebarVisible = false;
            this.onCloseSidebar('edit');
          },
          error: (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedUpdate });
            console.error(error);
            this.isUpdating = false;
          }
        });
    }
  }

  openEditMetadataSidebar() {
    if (!this.isEditMetadataAllowed || this.editDisabled) {
      return;
    }
    if (this.selectedDocuments[0].engagement) {
      this.editMetadata();
    } else {
      this.disableOrEnableEngagementAndWorkflow(true, this.getMetadata.selectedTaxpayer, false);
    }
  }

  disableOrEnableEngagementAndWorkflow(isDisabled: boolean, taxPayer: any, isWorkflowDocument: boolean) {
    this.disableEngagement = isDisabled;
    this.disableWorkflow = isDisabled;
    this.getMetadata.engagements = [];
    this.getMetadata.engagementId = "";
    this.getMetadata.selectedEngagement = undefined;
    this.getMetadata.workflows = [];
    this.getMetadata.workflowId = "";
    this.getMetadata.selectedWorkflow = undefined;
    this.getMetadata.doctypeGroups = [];
    this.getMetadata.doctypeGroupId = "";
    this.getMetadata.selectedDoctypeGroup = undefined;
    this.getMetadata.doctypes = [];
    this.getMetadata.doctypeId = "";
    this.getMetadata.selectedDoctype = undefined;
    this.getMetadata.doctypeDescs = [];
    this.getMetadata.doctypeDescId = "";
    this.getMetadata.selectedDoctypeDesc = undefined;
    this.getMetadata.doctype2ndDescs = [];
    this.getMetadata.doctype2ndDescId = "";
    this.getMetadata.selectedDoctype2ndDesc = undefined;

    if (this.disableEngagement == true && this.disableWorkflow == true) {
      this.selectedTaxYearForEdit = { name: "", code: "" };
      this.taxyeardisabled = false;
    }
    else {
      this.taxyeardisabled = true;
    }

    if ((isWorkflowDocument && isDisabled) || (!isWorkflowDocument && !isDisabled)) {
      this.metadataChangedForEdit(taxPayer, 'taxpayer');
    } else {
      //FYI: This is async, but since this is a final call and the method does not return, not awaiting it
      this.editMetadata();
    }
  }

  checkOutDocuments() {
    if (this.selectedDocuments.length > 1) {
      this.messageService.add({
        severity: 'warn', summary: messages.checkOutDocument, detail: messages.multipleDocCheckoutError
      });
    }
    else if (this.selectedDocuments[0]?.doccheckoutstatus === 'Checked Out') {
      this.messageService.add({
        severity: 'warn', summary: messages.checkOutDocument, detail: messages.checkOutValidation
      });
    }
    else if (this.selectedDocuments[0]?.statuscode === 'Archived') {
      this.messageService.add({ severity: 'warn', summary: 'Check Out', detail: messages.archivedValidationErrorSingleDoc });
    }
    else {
      const request: CheckInCheckOutRequest = {
        documentId: this.selectedDocuments[0]?.key || '',
        documentURL: this.selectedDocuments[0]?.url || '',
        checkedBy: this.userId
      };
      this.sharepointgraphService.checkOutDocument(request).subscribe({
        next: (response) => {
          this.messageService.add({ severity: 'info', summary: messages.checkOutDocument, detail: messages.checkOutSuccess });
          this.commonService.refreshOnOperationComplete(true);
        }, error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.checkOutFailure });
          console.error(error);
          //this.messageService.add({ severity: 'error', summary: messages.checkOutDocument, detail: messages.checkOutFailure });
        }
      });
    }
  }

  checkInDocuments() {
    if (this.selectedDocuments.length > 1) {
      this.messageService.add({
        severity: 'warn', summary: messages.checkInDocument, detail: messages.multipleDocCheckinError
      });
    } else if (this.selectedDocuments[0]?.doccheckoutstatus !== 'Checked Out') {
      this.messageService.add({
        severity: 'warn', summary: messages.checkInDocument, detail: messages.checkInValidation
      });
    }
    else if (this.selectedDocuments[0]?.statuscode === 'Archived') {
      this.messageService.add({ severity: 'warn', summary: 'Check In', detail: messages.archivedValidationErrorSingleDoc });
    }
    else {
      const request: CheckInCheckOutRequest = {
        documentId: this.selectedDocuments[0]?.key,
        documentURL: this.selectedDocuments[0]?.url,
        checkedBy: this.userId
      };
      this.sharepointgraphService.checkInDocument(request).subscribe({
        next: (response) => {
          this.messageService.add({ severity: 'info', summary: messages.checkInDocument, detail: messages.checkInSuccess });
          this.commonService.refreshOnOperationComplete(true);
        }, error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.checkInFailure });
          console.error(error);
        }
      });
    }
  }

  reloadSignOff() {
    this.commonService.refreshOnOperationComplete(true);
  }

  // Set reset roll forward flag for selected documents.
  setResetRollforward(isRollforward: any) {
    this.commonService.setResetSpinner(true);
    const requestDocuments = this.selectedDocuments.filter(x => x.workflow).map(x => x.key);
    if (requestDocuments.length < this.selectedDocuments.length) {
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: messages.invalidRollforwardWarning });
    }

    if (requestDocuments.length > 0) {
      const request: SetResetRollforwardRequest = {
        Documents: requestDocuments,
        IsRollforward: isRollforward
      }

      this.documentsService.setResetRollforward(request)
        .subscribe({
          next: () => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: isRollforward === true ? messages.setRollforwardSuccess : messages.resetRollforwardSuccess });
            this.commonService.refreshOnOperationComplete(true);
          },
          error: (response) => {
            this.commonService.setResetSpinner(false);
            response?.error && response?.error != "" && response?.error?.includes(messages.rollForwardErrorWorkflowNotSetup)
              ? this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.rollForwardErrorWorkflowNotSetup })
              : this.messageService.add({ severity: 'error', summary: 'Error', detail: isRollforward === true ? messages.setRollforwardError : messages.resetRollforwardError });
          }
        });
    } else {
      this.commonService.setResetSpinner(false);
    }
  }

  // Is Workflow active.
  isWorkflowNodeActive(workflowId: any) {
    this.isWorkflowActive = false;
    if (workflowId) {
      this.documentsService.isWorkflowNodeActive(workflowId)
        .subscribe({
          next: (response) => {
            this.isWorkflowActive = response === true;
          },
          error: () => { }
        });
    }
  }

  /*Code region for storing "new code" as of BDO adoption to keep some kind of organization of refactoring */

  //Signature here is insane, but one document upload validation checks a string value of a dropdown, where as update validation just checks the dropdown?
  private isWorkflowValidationPassing(source: Dropdown | string | undefined): boolean {
    return !!source
      || this.disableWorkflow
      || this.workflowDropdownOptionalAndReadonly;
  }

  private get needsCheckWorkflowDropdownOptional() {
    return this.engagement && !this.workflow;
  }

  private setWorkflowDropdownOptionalAndReadonly(engagementId: string | undefined): Promise<boolean> {
    return Promise.all([
      this.getAllowDirectDocumentUploadToPYEngagementConfigValue(),
      this.getSelectedEngagementAllowDocumentUploadValue(engagementId)
    ])
      .then(() => {
        if ((this.allowDirectDocumentUploadToPYEngagementConfigValue == "Yes") && (this.selectedEngagementAllowDocumentUploadValue == "Yes")) {
          this.workflowDropdownOptionalAndReadonly = true;
          //this.isApplicableTaxYearRequired = true;
        }
        else {
          this.workflowDropdownOptionalAndReadonly = false;
          //this.isApplicableTaxYearRequired = false;
        }

        return this.workflowDropdownOptionalAndReadonly;
      });
  }

  // Drag and drop to upload documents.
  onDocumentsDrop(event: any) {
    if (this.isUploadAllowed && this.clientValidation) {
      this.onSelectFile(event);
      if (event.currentFiles.length > 0) {
        this.files = event.currentFiles;
        this.openUploadSidebar();
      }
      this.fileUpload?.clear();
    }
    else {
      this.fileUpload?.clear();
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: messages.uploadNotAllowed });
    }
  }

  // Upload documents button action.
  onUploadDocumentsButtonClick() {
    this.files = [];
    this.openUploadSidebar();
  }

  // Open tag document sidebar.
  openTagDocumentSideBar() {
    this.tagDocumentSideBarVisible = true;
    this.commonService.disableRowSelectOnPanel(true);
    const isTaxpayerDocument = this.selectedDocuments[0].taxpayer && !this.selectedDocuments[0].engagement ? true : false;

    if (isTaxpayerDocument === true) {
      this.TagLabel = "Associated Taxpayers";
      this.TagPlaceholder = "Select taxpayers to associate with";
    } else {
      this.TagLabel = "Associated Taxpayers and Workflows";
      this.TagPlaceholder = "Select workflows to associate with";
    }


    const request: DocumentTagMetadataRequest = {
      DocumentId: this.selectedDocuments[0].key,
      ClientId: this.clientId,
      IsActive: this.selectedDocuments[0].statuscode !== 'Archived'
    }

    this.documentsService.getDocumentMetadataForTagging(request).subscribe({
      next: (response) => {
        this.tagDocumentDD = response.associations ?? null;
        if (isTaxpayerDocument === true) {
          this.associatedTags = response.associatedTaxpayers;
          this.tagDocumentDD = this.tagDocumentDD?.sort((a: any, b: any) => a.name.localeCompare(b.name));
          if (this.tagDocumentDD?.length > 0 && response.associatedTaxpayers.length > 0) {
            this.selectedTags = this.tagDocumentDD.filter(x => response.associatedTaxpayers.includes(x.value));
          }
        } else {
          this.associatedTags = response.associatedWorkflows;
          this.tagDocumentDD = this.tagDocumentDD?.sort((a: any, b: any) => a.name.localeCompare(b.name));
          if (this.tagDocumentDD?.length > 0 && response.associatedWorkflows.length > 0) {
            this.selectedTags = this.tagDocumentDD.filter(x => response.associatedWorkflows.includes(x.value));
          }
        }
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }

  // Tag document
  tagDocument() {
    try {
      if (this.isTagging !== true) {
        this.isTagging = true;
        const request: DocumentTagRequest = {
          DocumentId: this.selectedDocuments[0].key,
          IsTaxpayerDocument: this.selectedDocuments[0].taxpayer && !this.selectedDocuments[0].engagement ? true : false,
          TagsToAdd: this.selectedTags.filter(x => this.associatedTags.includes(x.value) === false).map(x => x.value),
          TagsToRemove: this.associatedTags.filter(x => this.selectedTags.map(x => x.value).includes(x) === false),
          SelectedTags: this.selectedTags.map(x => x.value)
        }

        this.documentsService.tagDocument(request)
          .subscribe({
            next: () => {
              this.isTagging = false;
              this.messageService.add({ severity: 'success', summary: 'Success', detail: messages.updateSuccess });
              this.commonService.refreshOnOperationComplete(true);
              this.onCloseSidebar('tagdocument');
            },
            error: () => {
              this.isTagging = false;
              this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedUpdate });
            }
          });
      }
    }
    catch (error) {
      this.isTagging = false;
      console.error(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedUpdate });
    }

  }

  // Toggle buttons in more option
  toggleButtonsInMoreOption(isWorkflowInactive: boolean) {
    // Check-In
    const checkInIdx = this.items.findIndex(x => x.label === Buttons.CheckIn);
    if (checkInIdx > -1) {
      this.items[checkInIdx].visible = this.isCheckInAllowed && !isWorkflowInactive;
    }

    // Check-Out
    const checkOutIdx = this.items.findIndex(x => x.label === Buttons.CheckOut);
    if (checkOutIdx > -1) {
      this.items[checkOutIdx].visible = this.isCheckOutAllowed && !isWorkflowInactive;
    }

    // Archive
    const archiveIdx = this.items.findIndex(x => x.label === Buttons.Archive);
    if (archiveIdx > -1) {
      this.items[archiveIdx].visible = this.isArchiveAllowed && !isWorkflowInactive;
    }

    // Unarchive
    const unarchiveIdx = this.items.findIndex(x => x.label === Buttons.Unarchive);
    if (unarchiveIdx > -1) {
      this.items[unarchiveIdx].visible = this.isUnarchiveAllowed && !isWorkflowInactive;
    }

    // Hold
    const holdIdx = this.items.findIndex(x => x.label === Buttons.Hold);
    if (holdIdx > -1) {
      this.items[holdIdx].visible = this.isHoldAllowed && !isWorkflowInactive;
    }

    // Unhold
    const unholdIdx = this.items.findIndex(x => x.label === Buttons.Unhold);
    if (unholdIdx > -1) {
      this.items[unholdIdx].visible = this.isUnholdAllowed && !isWorkflowInactive;
    }

    // Rollforward
    const rollforwardIdx = this.items.findIndex(x => x.label === Buttons.Rollforward);
    if (rollforwardIdx > -1) {
      this.items[rollforwardIdx].visible = this.isRollforwardAllowed && !isWorkflowInactive;
    }

    // Remove Rollforward
    const removeRollforwardIdx = this.items.findIndex(x => x.label === Buttons.RemoveRollforward);
    if (removeRollforwardIdx > -1) {
      this.items[removeRollforwardIdx].visible = this.isRemoveRollforwardAllowed && !isWorkflowInactive;
    }

    // Tag Document
    const tagDocumentIdx = this.items.findIndex(x => x.label === Buttons.TagDocument);
    if (tagDocumentIdx > -1) {
      this.items[tagDocumentIdx].visible = !this.isTagDocumentAllowed ||
        this.selectedDocuments.length !== 1
        || (this.selectedDocuments[0].engagement && !this.selectedDocuments[0].workflow) ? false : true;
    }
  }

  saveGridInformation($event: boolean = false) {
    if (this.saveDisabled) {
      return;
    }

    this.saveDisabled = $event;

    this.saveRequest = !$event;
  }

  checkUddUpdateDocuments($event: any) {
    let totalInvalidRecords = 0;
    if ($event && $event.length > 0) {
      totalInvalidRecords = $event.filter((x: any) => x?.statuscode == 'Archived' || this.isInvalidOperation($event) || !x.isPrimary)?.length;


      if (isNaN(totalInvalidRecords) || totalInvalidRecords !== 0 || totalInvalidRecords === $event.length) {
        this.saveDisabled = true;
      }
      else {
        this.saveDisabled = false
      }
    }
    else {
      this.saveDisabled = true;
    }
  }

  onDownloadDocumentsButtonClick() {
    if (this.selectedDocuments.length > 0) {
      if (this.selectedDocuments.length == 1) {
        Utilities.downloadDocument(this.selectedDocuments[0], this.sharepointgraphService, this.messageService);
      }
      if (this.selectedDocuments.length > this.zipFileMaxFileCount) {
        this.messageService.add({ severity: 'warn', summary: messages.zipFileWarning, detail: messages.zipFileMaxLimitExceededWarning.replace("[zipFileMaxFileCount]", this.zipFileMaxFileCount.toString()) });
        return;
      } else if (this.selectedDocuments.length > 1) {
        Utilities.downloadZipFile(this.selectedDocuments, this.sharepointgraphService, this.messageService, this.dialog);
      }
    }
  }
}
